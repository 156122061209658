import { Button } from "reactstrap";
import { getIcons } from "../../utils";
import { DragSource } from "react-dnd";

const LayerCard = (props) => {
  const {
    title,
    name,
    assetLink,
    customId,
    onButtonClick,
    isDragging,
    connectDragSource,
    equipped,
    clan,
  } = props;

  return connectDragSource(
    <div
      style={{
        opacity: isDragging ? 0.8 : 1,
        cursor: equipped ? "not-allowed" : "grab",
      }}
      className="layer-item"
    >
      <div className="layer-desc">
        <div className="title">{title}</div>
        <div className="desc">{name || "N/a"}</div>
        <Button
          onClick={() => {
            onButtonClick(title, assetLink, name, customId, clan);
          }}
          disabled={!name || equipped}
          className="layer-button"
        >
          {equipped ? "Equipped" : "Equip"}
        </Button>
      </div>
      <div className="layer-logo">{getIcons(title)}</div>
    </div>
  );
};

export default DragSource(
  ({ title: eligibleLayer }) => {
    return eligibleLayer;
  },
  {
    beginDrag: (props) => {
      return props;
    },
    canDrag: (props) => {
      const { equipped, name } = props;
      return name && !equipped;
    },
    endDrag(props, monitor) {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        const { assetLink, name, title, customId, clan } = props;
        props.onButtonClick(title, assetLink, name, customId, clan);
      }
    },
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })
)(LayerCard);
