import { useState } from "react";
export const CustomImg = (props) => {
  const [hasError, setError] = useState(false);
  if (hasError)
    return (
      <video
        autoPlay
        muted
        loop
        playsInline
        src="https://blockbots.mypinata.cloud/ipfs/QmVn9FqUZokznomiT5Dv5aPUjJdnm45YNwhTgeiNGkKr3K"
        alt=""
      />
    );
  return (
    <img
      src={props.src}
      onError={() => {
        setError(true);
      }}
      alt=""
    />
  );
};
