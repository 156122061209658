import { Collapse, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import {
  FaLayerGroup,
  FaChevronDown,
  FaQuestionCircle,
  FaInfoCircle,
} from "react-icons/fa";
// import { MdLandscape } from "react-icons/md";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { useState } from "react";
import { SidebarSkeletonLoading } from "./SidebarSkeletonLoading";
import { Link, useLocation } from "react-router-dom";
import { getIcons } from "../../utils";
import { onToggle } from "../../app/reducers/sidebarReducer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const Sidebar = (props) => {
  const { selectedBot = [], isConnected, basePowers } = props;
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();

  const sidebarOpen = useSelector(({ sidebar }) => {
    return sidebar.isOpen;
  });
  const healthPoints =
    basePowers &&
    Array.isArray(basePowers) &&
    basePowers.filter((elm) => elm.powerType === "HEALTH_POINTS")[0];

  const strengthPoints =
    basePowers &&
    Array.isArray(basePowers) &&
    basePowers.filter((elm) => elm.powerType === "STRENGTH")[0];

  const agilityPoints =
    basePowers &&
    Array.isArray(basePowers) &&
    basePowers.filter((elm) => elm.powerType === "AGILITY")[0];

  const defensePoints =
    basePowers &&
    Array.isArray(basePowers) &&
    basePowers.filter((elm) => elm.powerType === "DEFENSE")[0];

  const hideItems = location.pathname === "/faq";
  const botData = {
    Clan: "-",
    defaultProps: [
      { trait_type: "Clan", value: "-" },
      { trait_type: "Sub Clan", value: "-" },
      { trait_type: "Headgear", value: "-" },
      { trait_type: "Head", value: "-" },
      { trait_type: "Body", value: "-" },
      { trait_type: "Hands", value: "-" },
      { trait_type: "Legs", value: "-" },
    ],
    actualProps: [],
  };
  for (let i = 0; i < selectedBot.length; i += 1) {
    const { trait_type, value } = selectedBot[i];
    if (trait_type === "Clan") botData.Clan = value;

    botData.actualProps.push(selectedBot[i]);
  }
  const { Clan, actualProps, defaultProps } = botData;

  const onCollapse = () => {
    setCollapsed((prevState) => !prevState);
  };

  const getChildren = () => {
    if (!isConnected) return <SidebarSkeletonLoading />;
    return (
      <>
        <div className="sidebar-header">
          <div className={`sidebar-title ${sidebarOpen ? "" : "closed"}`}>
            Clan
            <div className="sidebar-clan-name">{Clan}</div>
          </div>
          <div
            onClick={() => {
              dispatch(onToggle());
            }}
            className="sidebar-collapse-control"
          >
            {sidebarOpen ? <AiOutlineMenuFold /> : <AiOutlineMenuUnfold />}
          </div>
        </div>
        {sidebarOpen && basePowers && basePowers.length > 0 && (
          <div className="sidebar-power-container">
            <div className="sidebar-powerup-title">
              <img src="/robot-head.png" alt="" />
              Base Attributes
              <FaInfoCircle id="attribute-info" />
              <UncontrolledTooltip target="attribute-info">
                These attributes might get slightly updated when the game
                launches.
              </UncontrolledTooltip>
            </div>
            {healthPoints && (
              <div className="sidebar-bp-power-label lime-green">
                <span className="d-flex align-items-center">
                  <img src="/Health.svg" alt="" />
                  Health Points
                </span>
                {healthPoints.value}
              </div>
            )}
            {strengthPoints && (
              <div className="sidebar-bp-power-label off-red-bck">
                <span className="d-flex align-items-center">
                  <img src="/Strength.svg" alt="" />
                  Strength
                </span>
                {strengthPoints.value}
              </div>
            )}
            {agilityPoints && (
              <div className="sidebar-bp-power-label off-yellow">
                <span className="d-flex align-items-center">
                  <img src="/Agility.svg" alt="" />
                  Agility
                </span>
                {agilityPoints.value}
              </div>
            )}
            {defensePoints && (
              <div className="sidebar-bp-power-label light-blue">
                <span className="d-flex align-items-center">
                  <img src="/Defence.svg" alt="" />
                  Defence
                </span>
                {defensePoints.value}
              </div>
            )}
          </div>
        )}
        <div className="sidebar-layers-container">
          <div>
            <div className="sidebar-layer-title">
              <div
                className={classnames("title", sidebarOpen ? "" : "zero-mr")}
              >
                <FaLayerGroup />
                <span className={`${sidebarOpen ? "" : "closed"}`}>Layers</span>
              </div>
              <FaChevronDown
                onClick={onCollapse}
                className={`sidebar-collapse-cheveron ${
                  collapsed ? "rotate-down" : ""
                } ${sidebarOpen ? "" : "closed"}`}
              />
            </div>
            <div>
              <Collapse
                style={{
                  marginTop: "24px",
                  display: sidebarOpen ? "" : "none",
                }}
                isOpen={collapsed}
              >
                {(actualProps.length > 0 ? actualProps : defaultProps).map(
                  (elem, idx) => {
                    const { trait_type, value } = elem;
                    return (
                      <div key={idx} className="sidebar-layer-collapse-item">
                        <div className="sidebar-layer-logo">
                          {getIcons(trait_type)}
                        </div>
                        <div className="sidebar-layer-desc">
                          <div className="title">{trait_type}</div>
                          <div className="desc">{value}</div>
                        </div>
                      </div>
                    );
                  }
                )}
              </Collapse>
            </div>
          </div>
        </div>
      </>
    );
  };
  const children = getChildren();
  return (
    <div className={`sidebar-wrapper ${sidebarOpen ? "" : "sidebar-closed"}`}>
      <div
        className={classnames(
          "sidebar-container",
          sidebarOpen ? "" : "align-items-center"
        )}
      >
        {!hideItems && children}
        {!hideItems && <hr />}
        {/* <div className="sidebar-extra-links">
          <FaInfoCircle />
          More Info
        </div> */}
        <div
          className={classnames(
            "sidebar-extra-links",
            sidebarOpen ? "" : "zero-mr"
          )}
        >
          <FaQuestionCircle />
          <span className={`${sidebarOpen ? "" : "closed"}`}>
            <Link to="/faq">FAQs</Link>
          </span>
        </div>
        {/* <div
          className={classnames(
            "sidebar-extra-links",
            sidebarOpen ? "" : "zero-mr"
          )}
        >
          <MdLandscape />
          <span className={`${sidebarOpen ? "" : "closed"}`}>
            Into the Metaverse
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default Sidebar;

// {/* <div className="sidebar-power-container">
//           <div className="sidebar-powerup-title">
//             <FaRobot />
//             <span className={`${sidebarOpen ? "" : "closed"}`}>Base Power</span>
//           </div>
//           <span
//             className={`sidebar-bp-power-label cyan-bck ${
//               sidebarOpen ? "" : "closed"
//             }`}
//           >
//             -
//           </span>
//         </div> */}

// <div className="sidebar-power-container">
//   <div className="sidebar-powerup-title">
//     <FaBolt style={{ fontSize: "20px" }} />
//     {/* <img src="/flash.png" alt="head" /> */}
//     <span className={`${sidebarOpen ? "" : "closed"}`}>
//       With Power-ups
//     </span>
//   </div>
//   <span
//     className={`sidebar-bp-power-label orange-bck ${
//       sidebarOpen ? "" : "closed"
//     }`}
//   >
//     -
//   </span>
// </div>
