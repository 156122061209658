import useContract from "./useContract";
import { blockBots } from "../blockchain";
import { useWeb3React } from "@web3-react/core";
import { useMemo, useCallback, useState, useEffect } from "react";
import { BLOCKBOT_CONTRACT_ADDRESS, API_URL } from "../constant";
import { toast } from "react-toastify";
// import { ethers } from "ethers";

export function useBlockBotsContract() {
  const { account, chainId } = useWeb3React();
  const bbContract = useContract(BLOCKBOT_CONTRACT_ADDRESS, blockBots.abi);
  const [changeNamePrice, setChangeNamePrice] = useState();
  const [indContractAddress, setIndContractAddress] = useState(null);
  const [changeNameInProgress, setChangeNameProgress] = useState(false);

  const [loading, setLoading] = useState(true);

  const getTokenIdsFromWallet = useCallback(async () => {
    setLoading(true);
    const tokenIds = await bbContract.walletOfOwner(account);
    setLoading(false);
    return tokenIds;
  }, [account, bbContract]);

  const getTokenURI = useCallback(
    async (excludeDeflatinated = false) => {
      setLoading(true);
      // setUrlArr(null);
      const uriArr = [];
      try {
        if (account) {
          const ids = await getTokenIdsFromWallet();
          if (ids && ids.length > 0) {
            for (let i = 0; i < ids.length; i += 1) {
              const elem = ids[i];
              const idsNum = elem.toNumber();
              if (excludeDeflatinated && idsNum >= 10000) {
                // console.log("skipping");
              } else uriArr.push(`${API_URL}/blockbot/${idsNum}`);
            }
          }
        }
        return uriArr;
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
        // return urlArr;
      }
    },
    [getTokenIdsFromWallet, account]
  );

  const getChangeNamePrice = useCallback(async () => {
    const changeNamePrice = await bbContract.nameChangePrice();
    setChangeNamePrice(changeNamePrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bbContract, chainId]);

  const getIndContractAddress = useCallback(async () => {
    const indAddress = await bbContract.INDORSE_TOKEN_ADDRESS();
    setIndContractAddress(indAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bbContract, chainId]);

  useEffect(() => {
    if (account) {
      getChangeNamePrice();
      getIndContractAddress();
    }
  }, [account, getChangeNamePrice, getIndContractAddress]);

  const changeBBName = useCallback(
    async (tokenId, newName) => {
      setChangeNameProgress(true);
      const isValid = await bbContract.validateName(newName);
      if (!isValid) {
        toast("Invalid Name", { toastId: "CHANGE_NAME" });
        throw new Error("Invalid Name");
      }
      const changeNameTrx = await bbContract.changeName(tokenId, newName);
      await changeNameTrx.wait();
      setChangeNameProgress(false);
    },
    [bbContract]
  );

  const checkOwnership = useCallback(
    async (tokenId) => {
      setLoading(true);
      const isOwner = (await bbContract.ownerOf(tokenId)) === account;
      setLoading(false);
      return isOwner;
    },
    [bbContract, account]
  );
  // console.log(loading);

  const getNextDeflatinatedTokenId = useCallback(async () => {
    const nextTokenId = await bbContract.getNextDeflatinatedTokenId();
    return nextTokenId.toNumber();
  }, [bbContract]);

  const deflatinate = useCallback(
    async (tokenOneId, tokenTwoId) => {
      const deflatinateTRX = await bbContract.deflatinate(
        tokenOneId,
        tokenTwoId
      );
      await deflatinateTRX.wait();
    },
    [bbContract]
  );

  const getParentIds = useCallback(
    async (newBotId) => {
      const tokenIds = await bbContract.getParentOfBots(newBotId);
      const parentOne = tokenIds["parent1"].toNumber();
      const parentTwo = tokenIds["parent2"].toNumber();
      return { parentOne, parentTwo };
    },
    [bbContract]
  );

  return useMemo(() => {
    if (!account) {
      // setUrlArr(null);
      // setLoading(false);
    }
    return {
      loadingBots: loading,
      // urlArr,
      getTokenIdsFromWallet,
      getTokenURI,
      changeNamePrice,
      changeBBName,
      indContractAddress,
      checkOwnership,
      changeNameInProgress,
      getNextDeflatinatedTokenId,
      deflatinate,
      getParentIds,
    };
  }, [
    account,
    loading,
    // urlArr,
    getTokenURI,
    changeNamePrice,
    changeBBName,
    indContractAddress,
    checkOwnership,
    changeNameInProgress,
    getNextDeflatinatedTokenId,
    deflatinate,
    getTokenIdsFromWallet,
    getParentIds,
  ]);
}
