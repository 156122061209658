import axios from "axios";
import { API_URL } from "../constant";

export const getAllBlockBots = async (tokenIds = []) => {
  const allRequests = tokenIds.map((elem) =>
    axios
      .get(`${API_URL}/blockbot/${elem.toNumber()}`)
      .then((resp) => resp)
      .catch(() => {
        return { data: { noMetadata: true, tokenId: elem.toNumber() } };
      })
  );
  const allData = await axios.all(allRequests).then(
    axios.spread((...resp) => {
      //   console.log(resp);
      const data = resp.map((elm) => elm.data);
      return data;
    })
  );
  return allData;
};

export const getBotStats = async (id) => {
  const { data } = await axios.get(`${API_URL}/bb-stats/${id}`);
  return data;
};

export const getBotByID = async (id) => {
  const { data } = await axios.get(`${API_URL}/blockbot/${id}`);
  return data;
};

export const getDeflatBotStatus = async (attributes) => {
  const { data } = await axios.post(`${API_URL}/deflatinate/demo`, {
    attributes,
  });
  return data;
};
