import { MeshStandardMaterial, sRGBEncoding } from "three";

const scaleFactor = 1;
const yRotationDeg = 0;

const degrees_to_radians = (deg) => (deg * Math.PI) / 180.0;

export function traverseMaterials(object, callback) {
  object.traverse((node) => {
    if (!node.isMesh) return;
    const materials = Array.isArray(node.material)
      ? node.material
      : [node.material];
    materials.forEach(callback);
  });
}

export function updateObject(root, position) {
  root.position.set(position.x, position.y, position.z);
  root.scale.set(scaleFactor, scaleFactor, scaleFactor);
  root.rotation.set(0, degrees_to_radians(yRotationDeg), 0);
  const encoding = sRGBEncoding;

  root.traverse((node) => {
    if (!node.isMesh) return;
    node.castShadow = true;
    node.receiveShadow = true;
    const baseMaterial = node.material;
    node.material = new MeshStandardMaterial();
    MeshStandardMaterial.prototype.copy.call(node.material, baseMaterial);
  });
  traverseMaterials(root, (material) => {
    material.uniformsNeedUpdate = true;
    if (material.map) material.map.encoding = encoding;
    if (material.emissiveMap) material.emissiveMap.encoding = encoding;
    if (material.map || material.emissiveMap) material.needsUpdate = true;
    if (material.map) material.map.anisotropy = 16;
  });
}
