import React from "react";

export const faq_list = {
  Basics: [
    {
      ques: "What is the BlockBots Dashboard website?",
      ans: (
        <React.Fragment>
          <p>
            A BlockBot is not just a cool digital image that you store in your
            wallet: it is a 3D voxel avatar that will be used across metaverses,
            that you can equip with PowerUps, and that will be playable in the
            upcoming Block-Bout game! The Dashboard Page is the first step to
            explore some of the features of your BlockBot in much more depth!
          </p>
          <ul className="faq-ul">
            <li>
              See how your BlockBot looks like in a 3D environment (you will be
              able to view it from all angles).
            </li>
            <li>
              Download your BlockBot PNG image and the 3D Voxel file (.obj or
              similar files).
            </li>
            <li>Rename your BlockBot (using IND).</li>
            <li>Equip and try out sample PowerUps.</li>
          </ul>
        </React.Fragment>
      ),
    },
    {
      ques: "What can I do on this website?",
      ans: (
        <React.Fragment>
          <p>You can</p>
          <ul className="faq-ul">
            <li>
              See how your BlockBot looks like in a 3D environment (you will be
              able to view it from all angles).
            </li>
            <li>
              Download your BlockBot PNG image and the 3D Voxel file (.obj or
              similar files).
            </li>
            <li>Rename your BlockBot (using IND).</li>
            <li>Equip and try out sample PowerUps.</li>
          </ul>
        </React.Fragment>
      ),
    },
    {
      ques: "I connected my Metamask, but I can not see any BlockBots. What to do?",
      ans: (
        <React.Fragment>
          <p>
            Please make sure you connect the right account from your Metamask
            which currently holds your BlockBot(s). Also, please make sure that
            you indeed hold BlockBots in this account.
          </p>
        </React.Fragment>
      ),
    },
    {
      ques: "Can I change the traits I see on the left side?",
      ans: (
        <React.Fragment>
          <p>No, the traits can not be changed.</p>
        </React.Fragment>
      ),
    },
    {
      ques: "What are these sample PowerUps?",
      ans: (
        <React.Fragment>
          <p>
            You can use these sample PowerUps to equip your BlockBot and get the
            updated appearance of your BlockBot (limited to this Dashboard
            website).
          </p>
        </React.Fragment>
      ),
    },
    {
      ques: "How do I get IND to change the name of my BlockBot?",
      ans: (
        <React.Fragment>
          <p>
            You can get IND from{" "}
            <a
              href="https://v2.info.uniswap.org/pair/0xd5b1523b48d41c46e5d1091a9b068159353ff3d7"
              referrerPolicy="no-referrer"
              target="blank"
            >
              Uniswap
            </a>
            .
          </p>
        </React.Fragment>
      ),
    },
    {
      ques: "Does changing my BlockBot appearance here change my BlockBot on Opensea?",
      ans: (
        <React.Fragment>
          <p>No, it doesn't.</p>
        </React.Fragment>
      ),
    },
    {
      ques: "What files can I export?",
      ans: (
        <React.Fragment>
          <p>You can export 3D Model (.glb) and .png files.</p>
        </React.Fragment>
      ),
    },
  ],
  Deflatinator: [
    {
      ques: "What does the deflatinator do?",
      ans: (
        <React.Fragment>
          <p>Deflatinator combines two BlockBots to create a new one.</p>
        </React.Fragment>
      ),
    },
    {
      ques: "What bots can I deflatinate?",
      ans: (
        <React.Fragment>
          <p>
            You can deflatinate any two bots. They just need to be in the same
            inventory (i.e. under the same ETH address)
          </p>
        </React.Fragment>
      ),
    },
    {
      ques: "Does the rarity update?",
      ans: (
        <React.Fragment>
          <p>Yes, the rarity changes when you deflatinate.</p>
        </React.Fragment>
      ),
    },
    {
      ques: "What happens to the old bots that are deflatinated?",
      ans: (
        <React.Fragment>
          <p>They disappear from your inventory as they get burned.</p>
        </React.Fragment>
      ),
    },
    {
      ques: "How accurate is the deflatinator ranking?",
      ans: (
        <React.Fragment>
          <p>It is an estimate so it might change the future.</p>
        </React.Fragment>
      ),
    },
    {
      ques: "What happens to the PowerUps of the original bots after deflatination?",
      ans: (
        <React.Fragment>
          <p>
            PowerUps held by the original bots will be transferred to the new
            bot, they will show up in the game. However you will never be able
            to trade these powerups on any marketplace.
          </p>
        </React.Fragment>
      ),
    },
    {
      ques: "Is this finished end product of deflatinator?",
      ans: (
        <React.Fragment>
          <p>No, this is a beta version.</p>
        </React.Fragment>
      ),
    },
  ],
};
