import { createReducer, createAction } from "@reduxjs/toolkit";

const initialState = {
  selectedBot: [],
  basePower: [],
};

export const onBotSelect = createAction("BOT_SELECT");
export const onReset = createAction("ON_RESET");
export const setBasePowers = createAction("SET_BASE_POWERS");

const defaultSortOrder = [
  "Clan",
  "Sub Clan",
  "Headgear",
  "Head",
  "Body",
  "Hands",
  "Legs",
  "Primary Weapon",
  "Secondary Weapon",
];

export const blockbotsReducer = createReducer(initialState, (builder) => {
  builder.addCase(onBotSelect, (state, action) => {
    state.selectedBot =
      action && action.payload && Array.isArray(action.payload)
        ? action.payload.sort(
            (a, b) =>
              defaultSortOrder.indexOf(a.trait_type) -
              defaultSortOrder.indexOf(b.trait_type)
          )
        : [];
  });
  builder.addCase(onReset, (state) => {
    state.selectedBot = [];
    state.basePower = [];
  });
  builder.addCase(setBasePowers, (state, action) => {
    state.basePower = action.payload;
  });
});

export default blockbotsReducer;
