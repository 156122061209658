import { createReducer, createAction } from "@reduxjs/toolkit";

const initialState = {
  isOpen: true,
};

export const onToggle = createAction("ON_TOGGLE");

export const sidebarReducer = createReducer(initialState, (builder) => {
  builder.addCase(onToggle, (state) => {
    state.isOpen = !state.isOpen;
  });
});

export default sidebarReducer;
