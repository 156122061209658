import { useState, useCallback, useMemo } from "react";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";

const useAxios = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const { account } = useWeb3React();

  const fetchData = useCallback(
    async (url, params) => {
      setLoading(true);
      setResponse(null);
      try {
        if (account) {
          if (Array.isArray(url) && url.length > 0) {
            const allRequests = url.map((elem) =>
              axios
                .get(elem)
                .then((resp) => resp)
                .catch(() => {
                  // console.log(err);
                  return null;
                })
            );
            await axios.all(allRequests).then(
              axios.spread((...resp) => {
                const data = resp.filter(Boolean).map((elm) => elm.data);
                setResponse(data);
                return data;
              })
            );
          } else {
            const resp = await axios.get(url, params && { params: params });
            const { data } = resp;
            setResponse(data);
            return data;
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [account]
  );

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData, account]);

  return useMemo(() => {
    if (!account) {
      setResponse(null);
      setLoading(false);
    }
    return { response, dataLoading: loading, fetchData };
  }, [account, loading, response, fetchData]);
};

export default useAxios;
