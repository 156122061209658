import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FaCheckCircle } from "react-icons/fa";

export const ChangeNameProgressModal = ({
  isOpen,
  waitingForApproval,
  changeNameInProgress,
}) => {
  return (
    <Modal className="progress-modal" size="lg" isOpen={isOpen}>
      <ModalHeader>Change Name</ModalHeader>
      <ModalBody>
        <div className="progress-modal-message">
          {waitingForApproval &&
            "Please Approve the spend limit for IND tokens"}
          {changeNameInProgress &&
            "Please Finalize and Confirm the transaction in Metamask"}
        </div>
        <div className="progress-modal-steps-container">
          <div className="progress-modal-step">
            {waitingForApproval ? (
              <div className="step-loader" />
            ) : (
              <FaCheckCircle />
            )}
            <div className="step-title">Approve Spend Limit</div>
          </div>
          <div className="progress-modal-step">
            {changeNameInProgress ? (
              <div className="step-loader" />
            ) : (
              <FaCheckCircle />
            )}
            <div className="step-title">Confirm Transaction</div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
