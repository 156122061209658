import React from "react";
import {
  Navbar,
  NavbarBrand,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { useRef, useEffect } from "react";

import Jazzicon from "@metamask/jazzicon";

import { truncateString } from "../../utils";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import useEagerConnect from "../../hooks/useEagerConnect";
import HamburgerMenu from "./HamburgerMenu";
// import { IS_PROD } from "../../constant";

const Header = (props) => {
  const { onConnectClick, isDisabled, account, selectedBot, showcaseRefFuncs } =
    props;
  const isMobile = useMediaQuery({ query: "(max-width: 650px)" });
  const iconRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const hideInteractions = location.pathname === "/faq";
  const tried = useEagerConnect();
  useEffect(() => {
    if (account && iconRef.current && !hideInteractions) {
      iconRef.current.innerHTML = "";
      iconRef.current.appendChild(
        Jazzicon(45, parseInt(account.slice(2, 10), 16))
      );
    }
  }, [account, hideInteractions]);

  const onNavClick = () => {
    navigate("/");
  };

  const downloadModel = showcaseRefFuncs && showcaseRefFuncs.downloadModel;
  const downloadPNG = showcaseRefFuncs && showcaseRefFuncs.downloadPNG;
  // console.log(isMobile);
  if (isMobile)
    return (
      <HamburgerMenu
        onConnectClick={onConnectClick}
        account={account}
        selectedBot={selectedBot}
        downloadModel={downloadModel}
        downloadPNG={downloadPNG}
      />
    );
  // const isProd = IS_PROD === "true";
  return (
    <div className="header-navbar">
      <Navbar className="header-nav-bar">
        <div
          onClick={onNavClick}
          className="d-flex align-items-center header-container"
        >
          <NavbarBrand className="header-bb-logo" />
          <div className="header-title">BlockBots</div>
        </div>
        <div className="header-nav-items mx-auto">
          <Link to="/">Home</Link>
          <Link to="/deflatinator">
            Deflatinator<sup> Beta</sup>
          </Link>
          <Link to="/bot-career">BlockBot Career</Link>
          <Link to="/faq">FAQ</Link>
        </div>
        {!hideInteractions && (
          <div className="d-flex align-items-center">
            {selectedBot.length > 0 && (
              <UncontrolledButtonDropdown
                disabled={!isDisabled}
                className="header-button"
              >
                <DropdownToggle caret>Download</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={downloadModel}>3D Model</DropdownItem>
                  {/* <DropdownItem onClick={downloadPNG}>PNG</DropdownItem> */}
                  <DropdownItem onClick={downloadPNG}>PFP</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            )}
            {tried && !account ? (
              <Button onClick={onConnectClick} className="header-button">
                Connect Wallet
              </Button>
            ) : (
              <div className="header-address">
                <span>{truncateString(account, 5, 11)}</span>
                <span className="header-profile-icon" ref={iconRef}></span>
              </div>
            )}
          </div>
        )}
      </Navbar>
    </div>
  );
};

export default Header;
