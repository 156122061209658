import clx from "classnames";
import { Button, Input, Table } from "reactstrap";
import { getBotStats, getBotByID } from "../../api";
import { useState } from "react";
import { toast } from "react-toastify";
import { Loader } from "../../components";

export const BlockbotStats = () => {
  const [careerData, setCareerData] = useState(null);
  const [searchInput, setSearchInput] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const onSearch = async () => {
    try {
      if (searchInput) {
        setCareerData(null);
        setLoading(true);
        const { basePower, gamesPlayed, won, lost, draw } = await getBotStats(
          searchInput
        );
        const { name, image, attributes, tokenId } = await getBotByID(
          searchInput
        );
        const clan = attributes.filter((elm) => elm.trait_type === "Clan")[0]
          .value;
        setCareerData({
          name,
          image,
          basePower,
          gamesPlayed,
          won,
          lost,
          clan,
          tokenId,
          draw,
        });
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      toast(`Can't find BlockBot, Please check the token Id`, {
        toastId: "ERROR_CHECK",
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && searchInput) {
      onSearch();
    }
  };

  const {
    gamesPlayed,
    won,
    lost,
    basePower = [],
    image = null,
    name,
    clan,
    tokenId,
    draw,
  } = careerData || {};

  return (
    <div className="career-wrapper">
      <div className={clx("container")}>
        <div className="career-content">
          <div className="career-search">
            <div className="career-text-input">
              <div className="career-search-label">BlockBot Id</div>
              <Input
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyPress={handleKeyDown}
                placeholder="Blockbot Token Id"
              />
            </div>
            <Button
              disabled={isLoading}
              onClick={onSearch}
              className="career-search-btn"
            >
              {isLoading ? "Please Wait" : "Search"}
            </Button>
          </div>
          <div className="career-blockbot-view">
            <div className="career-img-container">
              <img src={image ? image : "/placeholder-bb.png"} alt="" />
            </div>
            <div className="career-stats-container">
              <div className="career-stats-name-details">
                <div className="bot-stats-name-detail">
                  <div className="stats-name">{name ? name : "-"}</div>
                  <div className="stats-clan">{clan ? clan : "-"}</div>
                </div>
                <div className="stats-socials">
                  {tokenId && (
                    <a
                      href={`https://opensea.io/assets/0x945520a9f9f9a6a7679ceec5ed28ee19d2ac18b9/${tokenId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.svg"
                        alt=""
                      />
                    </a>
                  )}
                </div>
              </div>
              <div className="stats-details-container">
                <div className="stats-attributes">
                  <img src="/robot-head.png" alt="" />
                  Base Attributes
                </div>
                <div className="attr-wrapper">
                  {basePower.length > 0 ? (
                    basePower.map((elm, idx) => {
                      const { powerType, value } = elm;
                      return (
                        <StatAtrributeLabel
                          key={idx}
                          label={powerType}
                          value={value}
                        />
                      );
                    })
                  ) : (
                    <EmptyState isLoading={isLoading} />
                  )}
                </div>
                <hr />
                <div className="career-match-stats">
                  <div className="career-stats-title">Stats</div>
                  <div className="career-stats-table">
                    {!Number.isInteger(gamesPlayed) ? (
                      <EmptyState isLoading={isLoading} />
                    ) : (
                      <CareerTable
                        gamesPlayed={gamesPlayed}
                        won={won}
                        lost={lost}
                        draw={draw}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const labelStyleMap = {
  HEALTH_POINTS: {
    name: "Health Points",
    imgSrc: "/Health.svg",
    style: "lime-green",
  },
  STRENGTH: {
    name: "Strength",
    imgSrc: "/Strength.svg",
    style: "off-red-bck",
  },
  AGILITY: {
    name: "Agility",
    imgSrc: "/Agility.svg",
    style: "off-yellow",
  },
  DEFENSE: {
    name: "Defense",
    imgSrc: "/Defence.svg",
    style: "light-blue",
  },
};

const StatAtrributeLabel = ({ label, value }) => {
  const { name, imgSrc, style } = labelStyleMap[label];
  return (
    <div className={clx("attr-power-label", style)}>
      <span className="d-flex align-items-center">
        <img src={imgSrc} alt="" />
        {name}
      </span>
      {value}
    </div>
  );
};

const CareerTable = ({ gamesPlayed, won, lost, draw }) => {
  return (
    <Table
      borderless
      style={{ borderCollapse: "separate", borderSpacing: "0 5px" }}
    >
      <thead className="career-table-head">
        <tr>
          <th>Matches</th>
          <th>Wins</th>
          <th>Loss</th>
          <th>Draw</th>
        </tr>
      </thead>
      <tbody>
        <tr className="career-table-tr">
          <td>{gamesPlayed}</td>
          <td>{won}</td>
          <td>{lost}</td>
          <td>{draw}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const EmptyState = ({ isLoading }) => {
  return (
    <div className="career-empty-state">
      {isLoading ? (
        <Loader loaderText="Loading" />
      ) : (
        <>
          <img src="/BB_Logo_empty.png" alt="" />
          No Data to Display
        </>
      )}
    </div>
  );
};
