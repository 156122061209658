import { Vector3, Vector2, Matrix3, Texture } from "three";
//New ObjExporter With MTL Exporter
class OBJExporter {
  parse(object, fileName) {
    let output = "";
    let materials = {};
    let indexVertex = 0;
    let indexVertexUvs = 0;
    let indexNormals = 0;

    const vertex = new Vector3();
    // const color = new Color();
    const normal = new Vector3();
    const uv = new Vector2();

    const face = [];

    output += "mtllib " + fileName + ".mtl\n";

    function parseMesh(mesh) {
      let nbVertex = 0;
      let nbNormals = 0;
      let nbVertexUvs = 0;

      const geometry = mesh.geometry;
      const material = mesh.material;

      const normalMatrixWorld = new Matrix3();

      if (geometry.isBufferGeometry !== true) {
        throw new Error(
          "THREE.OBJExporter: Geometry is not of type THREE.BufferGeometry."
        );
      }

      // shortcuts
      const vertices = geometry.getAttribute("position");
      const normals = geometry.getAttribute("normal");
      const uvs = geometry.getAttribute("uv");
      const indices = geometry.getIndex();

      // name of the mesh object
      output += "o " + mesh.name + "\n";

      // name of the mesh material
      if (mesh.material && mesh.material.name) {
        output += "usemtl " + mesh.material.name + "\n";
      }

      // vertices

      if (vertices !== undefined) {
        for (let i = 0, l = vertices.count; i < l; i++, nbVertex++) {
          vertex.x = vertices.getX(i);
          vertex.y = vertices.getY(i);
          vertex.z = vertices.getZ(i);

          // transform the vertex to world space
          vertex.applyMatrix4(mesh.matrixWorld);

          // transform the vertex to export format
          output += "v " + vertex.x + " " + vertex.y + " " + vertex.z + "\n";
        }
      }

      // uvs

      if (uvs !== undefined) {
        for (let i = 0, l = uvs.count; i < l; i++, nbVertexUvs++) {
          uv.x = uvs.getX(i);
          uv.y = uvs.getY(i);

          // transform the uv to export format
          output += "vt " + uv.x + " " + uv.y + "\n";
        }
      }

      // normals

      if (normals !== undefined) {
        normalMatrixWorld.getNormalMatrix(mesh.matrixWorld);

        for (let i = 0, l = normals.count; i < l; i++, nbNormals++) {
          normal.x = normals.getX(i);
          normal.y = normals.getY(i);
          normal.z = normals.getZ(i);

          // transform the normal to world space
          normal.applyMatrix3(normalMatrixWorld).normalize();

          // transform the normal to export format
          output += "vn " + normal.x + " " + normal.y + " " + normal.z + "\n";
        }
      }

      // faces

      if (indices !== null) {
        for (let i = 0, l = indices.count; i < l; i += 3) {
          for (let m = 0; m < 3; m++) {
            const j = indices.getX(i + m) + 1;

            face[m] =
              indexVertex +
              j +
              (normals || uvs
                ? "/" +
                  (uvs ? indexVertexUvs + j : "") +
                  (normals ? "/" + (indexNormals + j) : "")
                : "");
          }

          // transform the face to export format
          output += "f " + face.join(" ") + "\n";
        }
      } else {
        for (let i = 0, l = vertices.count; i < l; i += 3) {
          for (let m = 0; m < 3; m++) {
            const j = i + m + 1;

            face[m] =
              indexVertex +
              j +
              (normals || uvs
                ? "/" +
                  (uvs ? indexVertexUvs + j : "") +
                  (normals ? "/" + (indexNormals + j) : "")
                : "");
          }

          // transform the face to export format
          output += "f " + face.join(" ") + "\n";
        }
      }

      if (material.name !== "") output += "usemtl " + material.name + "\n";
      else output += "usemtl material" + material.id + "\n";
      materials[material.id] = material;

      // update index
      indexVertex += nbVertex;
      indexVertexUvs += nbVertexUvs;
      indexNormals += nbNormals;
    }

    object.traverse(function (child) {
      if (child.isMesh === true) {
        parseMesh(child);
      }
    });

    let mtlOutput = "";

    for (let key in materials) {
      let mat = materials[key];

      if (mat.name !== "") mtlOutput += "newmtl " + mat.name + "\n";
      else mtlOutput += "newmtl material" + mat.id + "\n";

      mtlOutput += "Ns 10.0000\n";
      mtlOutput += "Ni 1.5000\n";
      mtlOutput += "d 1.0000\n";
      mtlOutput += "Tr 0.0000\n";
      mtlOutput += "Tf 1.0000 1.0000 1.0000\n";
      mtlOutput += "illum 2\n";
      mtlOutput +=
        "Ka " +
        mat.color.r +
        " " +
        mat.color.g +
        " " +
        mat.color.b +
        " " +
        "\n";
      mtlOutput +=
        "Kd " +
        mat.color.r +
        " " +
        mat.color.g +
        " " +
        mat.color.b +
        " " +
        "\n";
      mtlOutput += "Ks 0.0000 0.0000 0.0000\n";
      mtlOutput += "Ke 0.0000 0.0000 0.0000\n";

      if (mat.map && mat.map instanceof Texture) {
        var file = "default.png";
        mtlOutput += "map_Ka " + file + "\n";
        mtlOutput += "map_Kd " + file + "\n";
      }
    }

    return { obj: output, mtl: mtlOutput };
  }
}

export { OBJExporter };
