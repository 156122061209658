import * as THREE from "three";
import { useEffect, useRef, useCallback, useMemo, useState } from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { updateObject, traverseMaterials } from "../../utils/threeUtils";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { useMediaQuery } from "react-responsive";

const lightColor = 0xffffff;
// const groupNames = [
//   "head",
//   "headGear",
//   "hands",
//   "body",
//   "legs",
//   "primaryWeapon",
//   "secondaryWeapon",
//   "Scene",
// ];
// const defaultBotConfig = {
//   Head: "/models/default/default_head",
//   Body: "/models/default/default_body",
//   Hands: "/models/default/default_hands",
//   Legs: "/models/default/default_legs",
// };

export default function useThree(config) {
  let animateFrame = useRef();
  const mountRef = useRef();
  const { current } = mountRef;
  const isMobile = useMediaQuery({ query: "(max-width: 650px)" });
  const isMediumScreen = useMediaQuery({ query: "(max-width: 1440px)" });
  const [selectedColor, setColor] = useState("#7ebaff");
  const controlsConfig = config && config.controls;
  const cameraConfig = config && config.camera;

  const scene = useMemo(() => {
    const scene = new THREE.Scene();
    scene.autoUpdate = true;
    return scene;
  }, []);

  const renderer = useMemo(() => {
    if (!current) return;
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
      clearAlpha: 0,
    });
    let height = current.clientHeight;
    let width = current.clientWidth;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 2.5;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.shadowMap.needsUpdate = true;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.gammaInput = true;
    renderer.gammaOutput = true;
    renderer.physicallyBasedShading = true;
    renderer.setSize(width, height);
    current.appendChild(renderer.domElement);

    return renderer;
  }, [current]);

  const camera = useMemo(() => {
    if (!current) return;
    const height = current.clientHeight;
    const width = current.clientWidth;
    const camera = new THREE.OrthographicCamera(
      width / -2,
      width / 2,
      height / 2,
      height / -2,
      1,
      1000
    );
    const staticZoom = cameraConfig && cameraConfig.staticZoom;
    camera.position.set(-245.6, 49, -107.8);
    camera.rotation.set(
      -2.727376876729826,
      -1.1233187600757772,
      -2.764223806422503
    );
    camera.castShadow = true;
    camera.receiveShadow = true;
    camera.zoom = staticZoom || isMobile ? 70 : isMediumScreen ? 100 : 126;
    camera.updateProjectionMatrix();
    scene.add(camera);
    return camera;
  }, [cameraConfig, current, isMediumScreen, isMobile, scene]);

  const controls = useMemo(() => {
    if (!renderer) return;
    const controls = new OrbitControls(camera, renderer.domElement);
    const maxZoom = controlsConfig && controlsConfig.maxZoom;
    const enableZoom = controlsConfig && controlsConfig.enableZoom;
    controls.screenSpacePanning = true;
    controls.target.set(0, 1.5, 0);
    controls.minZoom = 70.0;
    controls.maxZoom = maxZoom || 200.0;
    controls.enableZoom = enableZoom === "false" ? false : true;
    controls.update();
    return controls;
  }, [camera, controlsConfig, renderer]);

  const lighting = useMemo(() => {
    const shadowSize = 2000;
    const directionalLight = new THREE.DirectionalLight(lightColor, 0.5);
    directionalLight.position.set(-5, 17, 10);
    directionalLight.lookAt(0, 1, 0);
    directionalLight.castShadow = true;
    directionalLight.shadow.camera.top = shadowSize;
    directionalLight.shadow.camera.bottom = -shadowSize;
    directionalLight.shadow.camera.left = -shadowSize;
    directionalLight.shadow.camera.right = shadowSize;
    scene.add(directionalLight);

    const backDirectionalLight = new THREE.DirectionalLight(lightColor, 0.3);
    backDirectionalLight.position.set(-9, 18, -48);
    backDirectionalLight.lookAt(0, 1, 0);
    scene.add(backDirectionalLight);

    const newLight = new THREE.DirectionalLight(lightColor, 0.2);
    newLight.position.set(150, 18, -48);
    newLight.lookAt(0, 1, 0);
    scene.add(newLight);
  }, [scene]);

  // const { current } = containerRef;

  const renderScene = useCallback(() => {
    if (renderer) renderer.render(scene, camera);
  }, [camera, renderer, scene]);

  const animate = useCallback(() => {
    renderScene();
    animateFrame.current = window.requestAnimationFrame(animate);
  }, [renderScene]);

  const clearScene = useCallback(
    (groupNames) => {
      groupNames.forEach((name) => {
        const grp = scene.getObjectByName(name);
        if (grp && grp.keepMe) return;
        if (grp) scene.remove(grp);
      });
    },
    [scene]
  );

  // const redrawScene = useCallback(() => {
  //   sceneRef.current.forEach((group) => {
  //     // group.scale.set(0.5, 0.5, 0.5);
  //     scene.add(group);
  //   });
  // }, [scene]);

  const handleResize = useCallback(
    (sidebarOpen) => {
      if (!current) return;

      const width = current.clientWidth;
      const height = current.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      const aspect = sidebarOpen ? 1 : 0.8;
      scene.traverse((child) => {
        if (child instanceof THREE.Group) {
          child.scale.set(aspect, 1, aspect);
        }
      });
      renderScene();
    },
    [camera, current, renderScene, renderer, scene]
  );

  useEffect(() => {
    if (!current) return;

    let frameId;

    // const animate = () => {
    //   renderScene();
    //   frameId = window.requestAnimationFrame(animate);
    // };
    const start = () => {
      if (!frameId) {
        frameId = requestAnimationFrame(animate);
      }
    };
    window.addEventListener("resize", handleResize);
    const stop = () => {
      cancelAnimationFrame(frameId);
    };

    start();

    return () => {
      stop();
      // current && current.removeChild(renderer.domElement);
      window.removeEventListener("resize", handleResize);
    };
  }, [animate, current, handleResize, renderScene, renderer]);

  useEffect(() => {
    if (scene && current) scene.background = new THREE.Color(selectedColor);
  }, [current, scene, selectedColor]);

  const loadLayer = useCallback(
    (asset, layerName) => {
      if (!asset) return;
      const mtlLoader = new MTLLoader();
      mtlLoader.load(`${asset}.mtl`, (material) => {
        material.preload();
        const objLoader = new OBJLoader();
        objLoader.setMaterials(material);
        objLoader.load(
          `${asset}.obj`,
          (obj) => {
            const root = obj;
            updateObject(root, {
              x: 0,
              y: 0,
              z: 0,
            });
            root.name = layerName;
            scene.add(root);
          },
          function (xhr) {
            // console.log((xhr.loaded / xhr.total) * 100 + " % loaded");
          },
          function (error) {
            console.log(error);
          }
        );
      });
    },
    [scene]
  );

  const loadBackdrop = useCallback(
    (excludeFromClear = false) => {
      const gltfLoader = new GLTFLoader();
      gltfLoader.load(
        `/models/Backdrop/backdrop_final.glb`,
        (gltf) => {
          const root = gltf.scene;
          const encoding = THREE.sRGBEncoding;
          root.traverse((node) => {
            if (!node.isMesh) return;
            node.material.metalness = 1;
            node.receiveShadow = true;
          });
          traverseMaterials(root, (material) => {
            material.uniformsNeedUpdate = true;
            if (material.emissiveMap) material.emissiveMap.encoding = encoding;
            if (material.map || material.emissiveMap)
              material.needsUpdate = true;
            if (material.map) material.map.anisotropy = 16;
          });
          if (excludeFromClear) root.keepMe = true;
          scene.add(root);
        },
        function (xhr) {
          console.log((xhr.loaded / xhr.total) * 100 + " % loaded");
        },
        function (error) {
          console.log(error);
        }
      );
    },
    [scene]
  );

  // useEffect(() => {
  //   if (config && config.loadDefault) {
  //     Object.keys(defaultBotConfig).forEach((layer) => {
  //       const asset = defaultBotConfig[layer];
  //       loadLayer(asset, layer);
  //     });
  //   }
  // }, [config, loadLayer]);

  // const loadDefaultLayer = useCallback(() => {
  //   if (config && config.loadDefault) {
  //     Object.keys(defaultBotConfig).forEach((layer) => {
  //       const asset = defaultBotConfig[layer];
  //       loadLayer(asset, layer);
  //     });
  //   }
  // }, [config, loadLayer]);

  // useEffect(() => {
  //   loadDefaultLayer();
  // }, [loadDefaultLayer]);

  const removeLayer = useCallback(
    (layerName) => {
      // console.log(scene);
      const layer = scene.getObjectByName(layerName);
      // console.log(layer);
      if (layer) scene.remove(layer);
    },
    [scene]
  );

  const updateLayer = useCallback(
    (layerName, assetLink) => {
      // console.log(layerName);
      removeLayer(layerName);
      loadLayer(assetLink, layerName);
    },
    [loadLayer, removeLayer]
  );

  return {
    mountRef,
    controls,
    lighting,
    selectedColor,
    scene,
    renderer,
    camera,
    loadLayer,
    loadBackdrop,
    setColor,
    handleResize,
    updateLayer,
    removeLayer,
    clearScene,
    // loadDefaultLayer,
  };
}
