import { UnsupportedChainIdError } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { toast } from "react-toastify";
import { CHAIN_NAME } from "../constant";

const TOAST_ID_NOT_FOUND = "not-found";
export const TOAST_ID_WRONG_CHAIN = "wrong-chain";
const TOAST_ID_REJECTED_REQUEST = "rejected-request";

// Handle errors for all connectors here
export const getErrorMessage = (error) => {
  if (error instanceof NoEthereumProviderError) {
    toast(
      "🦊 Metamask wallet not found, Please install Metamask to continue.",
      {
        toastId: TOAST_ID_NOT_FOUND,
        autoClose: 999999,
      }
    );
    return;
  } else if (error instanceof UnsupportedChainIdError) {
    toast(`Please connect to ${CHAIN_NAME} to continue.`, {
      toastId: TOAST_ID_WRONG_CHAIN,
      autoClose: 999999,
    });
    return;
  } else if (error instanceof UserRejectedRequestErrorInjected) {
    toast("Please press confirm on your Metamask to continue.", {
      toastId: TOAST_ID_REJECTED_REQUEST,
    });
    return;
  } else {
    console.error(error);
    return;
  }
};
