import { Modal, ModalBody, Button, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { SUB_CLAN_PRIORITY } from "../../constant";
import { getDeflatBotStatus } from "../../api";
import { Loader } from "../../components";
import { FaInfoCircle } from "react-icons/fa";

const botAssetArr = [
  "Headgear",
  "Head",
  "Body",
  "Hands",
  "Legs",
  "Primary Weapon",
  "Secondary Weapon",
];

const clanPriority = {
  Citizen: 0,
  Soldier: 1,
  Wizard: 2,
  Superhero: 3,
  Royalty: 4,
};

export const StatsModal = ({
  isOpen,
  onCancel,
  onConfirm,
  newBot,
  renderer,
  botOne,
  botTwo,
  botClan,
}) => {
  const [imgUrl, setImgUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [basePoints, setBasePoints] = useState({
    HEALTH_POINTS: 0,
    STRENGTH: 0,
    AGILITY: 0,
    DEFENSE: 0,
  });

  useEffect(() => {
    if (!imgUrl) {
      const strMime = "image/jpeg";
      const imgData = renderer.domElement.toDataURL(strMime);
      setImgUrl(imgData);
    }
  }, [imgUrl, renderer.domElement]);

  const fetchBotStats = useCallback(
    async (attributes) => {
      try {
        const { basePower } = await getDeflatBotStatus(attributes);
        let ob = {};

        basePower.forEach((elm) => {
          ob[elm.powerType] = `${elm.minValue} - ${elm.maxValue}`;
        });
        setBasePoints(ob);
        setLoading(false);
      } catch (err) {
        console.log(err);
        onCancel();
      }
    },
    [onCancel]
  );

  useEffect(() => {
    let notComplete = false;
    const attributes = [];
    botAssetArr.forEach((elem) => {
      const asset = newBot[elem];
      const { name, required, clan } = asset;
      if (required && name === "") notComplete = true;
      if (name !== "") attributes.push({ trait_type: elem, value: name, clan });
    });
    if (notComplete) {
      toast(
        <span>
          Required layers are missing for deflatination.
          <br /> Please check that you have atleast equipped Head, Body, Hands
          and Legs layers
        </span>,
        {
          toastId: "NOT_COMPLETE",
        }
      );
      onCancel();
      return;
    }
    const newClan =
      clanPriority[botOne.clanOb.Clan] > clanPriority[botTwo.clanOb.Clan]
        ? botOne.clanOb
        : botTwo.clanOb;
    const newSubClan =
      SUB_CLAN_PRIORITY[botOne.clanOb["Sub Clan"]] >
      SUB_CLAN_PRIORITY[botTwo.clanOb["Sub Clan"]]
        ? botOne.clanOb
        : botTwo.clanOb;
    attributes.push(
      { trait_type: "Clan", value: newClan.Clan, clan: newClan.Clan },
      {
        trait_type: "Sub Clan",
        value: newSubClan["Sub Clan"],
        clan: newSubClan.Clan,
      }
    );
    if (attributes.length > 0) {
      fetchBotStats(attributes);
    }
  }, [botOne.clanOb, botTwo.clanOb, fetchBotStats, newBot, onCancel]);

  const { HEALTH_POINTS, STRENGTH, AGILITY, DEFENSE } = basePoints;
  return (
    <Modal siz="lg" className="confirm-modal-wrapper" centered isOpen={isOpen}>
      {loading ? (
        <div className="empty-state" style={{ height: "529px" }}>
          <Loader />
        </div>
      ) : (
        <ModalBody>
          <div className="confirm-modal-title">Deflatination Stats</div>
          <div className="modal-stats-container">
            {imgUrl && (
              <div className="confirm-modal-image">
                <img src={imgUrl} alt="" />
              </div>
            )}
            <div className="stats">
              <div className="stats-clan-title">
                Clan <br />
                {botClan && <span>{botClan}</span>}
              </div>
              <div className="sidebar-powerup-title">
                <img src="/robot-head.png" alt="" />
                Base Attributes
                <FaInfoCircle id="attribute-info" />
                <UncontrolledTooltip target="attribute-info">
                  Final values will be somewhere in between these ranges after
                  you finalize.
                </UncontrolledTooltip>
              </div>
              {HEALTH_POINTS && (
                <div className="sidebar-bp-power-label lime-green">
                  <span className="d-flex align-items-center">
                    <img src="/Health.svg" alt="" />
                    Health Points
                  </span>
                  {HEALTH_POINTS}
                </div>
              )}
              {STRENGTH && (
                <div className="sidebar-bp-power-label off-red-bck">
                  <span className="d-flex align-items-center">
                    <img src="/Strength.svg" alt="" />
                    Strength
                  </span>
                  {STRENGTH}
                </div>
              )}
              {AGILITY && (
                <div className="sidebar-bp-power-label off-yellow">
                  <span className="d-flex align-items-center">
                    <img src="/Agility.svg" alt="" />
                    Agility
                  </span>
                  {AGILITY}
                </div>
              )}
              {DEFENSE && (
                <div className="sidebar-bp-power-label light-blue">
                  <span className="d-flex align-items-center">
                    <img src="/Defence.svg" alt="" />
                    Defence
                  </span>
                  {DEFENSE}
                </div>
              )}
            </div>
          </div>
          <div className="confirm-modal-actions">
            <Button
              onClick={onCancel}
              className={classnames("control-btn-white")}
            >
              Cancel
            </Button>
            <Button onClick={onConfirm} className="control-btn-white blue-bg">
              {"Confirm"}
            </Button>
          </div>
        </ModalBody>
      )}
    </Modal>
  );
};
