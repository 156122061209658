import { useEffect, useCallback } from "react";
// import { toast } from "react-toastify";
// import { TOAST_ID_WRONG_CHAIN } from "../blockchain";
import { CHAIN_HEX } from "../constant";

export function useAutoSwitchNetwork() {
  const switchNetork = useCallback(async (eth) => {
    try {
      const chainIdHex = await eth.request({ method: "eth_chainId" });
      if (chainIdHex !== CHAIN_HEX)
        await eth.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: CHAIN_HEX }],
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const { ethereum } = window;
    if (ethereum) {
      switchNetork(ethereum);
    } else {
      console.log("ETHEREUM NOT FOUND");
    }
  }, [switchNetork]);
}
