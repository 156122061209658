import useContract from "./useContract";
import { IND_ERC20 } from "../blockchain";
import { useWeb3React } from "@web3-react/core";
import { useMemo, useCallback, useState } from "react";
import { BLOCKBOT_CONTRACT_ADDRESS } from "../constant";
import { BigNumber } from "@ethersproject/bignumber";
import { toast } from "react-toastify";
// import { ethers } from "ethers";

const MaxUint = BigNumber.from(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
);

export function useINDContaract(changeNamePrice, indContractAddress) {
  const { account } = useWeb3React();

  const indContract = useContract(indContractAddress, IND_ERC20.abi);
  const [loading, setLoading] = useState(true);

  const checkAllowance = useCallback(async () => {
    const allowance = await indContract.allowance(
      account,
      BLOCKBOT_CONTRACT_ADDRESS
    );
    return allowance;
  }, [account, indContract]);

  const getINDBalance = useCallback(async () => {
    const indBalance = await indContract.balanceOf(account);
    return indBalance;
  }, [account, indContract]);

  const getApproval = useCallback(async () => {
    setLoading(true);
    const indBalance = await getINDBalance();

    if (changeNamePrice.gt(indBalance)) {
      toast("You don't have sufficient IND Tokens", {
        tokenId: "INSUFFICIENT_BALANCE",
      });
      throw new Error("Insufficient Balance");
    }

    const allowed = await checkAllowance();

    if (changeNamePrice.gt(allowed)) {
      const trx = await indContract.approve(BLOCKBOT_CONTRACT_ADDRESS, MaxUint);
      await trx.wait();
    }
    setLoading(false);
  }, [changeNamePrice, checkAllowance, getINDBalance, indContract]);

  return useMemo(() => {
    return { waitingForApproval: loading, getApproval };
  }, [loading, getApproval]);
}
