import React, { useState } from "react";
import {
  Collapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { useParams } from "react-router";

import { faq_list } from "./faq_list";
const TAB_SECTIONS = {
  BASICS: "Basics",
  DEFLATINATOR: "Deflatinator",
};

export const Card = ({ children, className, onClick }) => {
  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};

const FAQ = () => {
  const params = useParams();
  const sectionId = params["*"];
  const currentSection =
    sectionId === "deflatinator"
      ? TAB_SECTIONS.DEFLATINATOR
      : TAB_SECTIONS.BASICS;
  const [collapsed, setCollapsed] = useState(0);
  const [activeTab, setActiveTab] = useState(currentSection);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="faq-container">
      <div className="faq-page-title">Frequently Asked Questions</div>
      <div className="faq-wrapper">
        <div className={"container"}>
          <Nav className="faq-tabs" tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === TAB_SECTIONS.BASICS,
                })}
                onClick={() => {
                  toggle(TAB_SECTIONS.BASICS);
                }}
              >
                Basics
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === TAB_SECTIONS.DEFLATINATOR,
                })}
                onClick={() => {
                  toggle(TAB_SECTIONS.DEFLATINATOR);
                }}
              >
                Deflatinator
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            {Object.keys(faq_list).map((ele) => {
              const currentTabItems = faq_list[ele];
              return currentTabItems.map((faq, idx) => (
                <TabPane key={idx} tabId={ele}>
                  <Card
                    key={idx}
                    borderless
                    className={classnames(
                      "faq-collapse",
                      "mx-auto",
                      collapsed === idx ? "faq-collapsed" : ""
                    )}
                    onClick={() => setCollapsed(collapsed !== idx ? idx : null)}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      className="d-flex justify-content-between"
                    >
                      <h2
                        className={classnames(
                          "collapse-header-title",
                          collapsed === idx ? "collapsed-title" : "",
                          "d-flex align-items-center justify-content-between mb-2"
                        )}
                      >
                        {faq.ques}{" "}
                      </h2>
                      <div className={classnames("arrow-container")}>
                        {idx === collapsed ? (
                          <FaChevronUp />
                        ) : (
                          <FaChevronDown />
                        )}
                      </div>
                    </div>
                    <Collapse
                      className={classnames("mt-2", "mobile-padding")}
                      isOpen={idx === collapsed}
                    >
                      {faq.ans}
                    </Collapse>
                  </Card>
                </TabPane>
              ));
            })}
          </TabContent>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
