import { useEffect, useState } from "react";

import withWeb3 from "../../hoc/withWeb3";
// import { useState } from "react";
import { Loader } from "../../components";
import { onBotSelect } from "../../app/reducers/blockbotReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import clx from "classnames";
import { Button } from "reactstrap";
import { CustomImg } from "../../components";
import { getAllBlockBots } from "../../api";

const Home = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { account, loading, getTokenIdsFromWallet, getParentIds } = props;

  const sidebarOpen = useSelector(({ sidebar }) => {
    return sidebar.isOpen;
  });

  const onBotCardClick = (attributes, tokenId) => {
    dispatch(onBotSelect(attributes));
    navigate(`/showcase/${tokenId}`);
  };
  // const { dataLoading, response: botsDataArr, fetchData } = useAxios();

  const [botsResponse, setBotsResponse] = useState({
    dataLoading: true,
    botsDataArr: [],
  });
  useEffect(() => {
    const fetchAllData = async () => {
      setBotsResponse(() => ({
        dataLoading: true,
        botsDataArr: [],
      }));
      const tokenIds = await getTokenIdsFromWallet();
      const allData = await getAllBlockBots(tokenIds);
      setBotsResponse(() => ({
        dataLoading: false,
        botsDataArr: allData,
      }));
    };
    if (account) {
      fetchAllData();
    }
  }, [account, getTokenIdsFromWallet]);

  const onIncompleteBotClick = async (botId) => {
    const parentIds = await getParentIds(botId);
    const { parentOne, parentTwo } = parentIds;
    navigate(`/deflatinator/${parentOne}/${parentTwo}/${botId}`);
  };

  const getChildren = () => {
    const { dataLoading, botsDataArr } = botsResponse;
    // console.log({ botsDataArr, loading, dataLoading });
    if (loading || dataLoading)
      return (
        <div className="empty-state">
          <Loader />
        </div>
      );
    if (botsDataArr.length > 0)
      return (
        <div className="bots-container">
          {botsDataArr.map((elem, idx) => {
            const { image, name, attributes, tokenId, noMetadata } = elem;
            if (!noMetadata) {
              const clanAttr = attributes.filter(
                (elm) => elm.trait_type === "Clan"
              );
              const { value } = clanAttr[0];
              return (
                <div
                  key={idx}
                  onClick={() => {
                    onBotCardClick(attributes, tokenId);
                  }}
                  className={clx("bots-card", sidebarOpen ? "" : "card-sm")}
                >
                  <div className="bots-card-img-cont">
                    <CustomImg src={image} />
                    {/* <img src={image} alt="" onError={onImgLoad} /> */}
                  </div>
                  <div className="bots-details">
                    <div>
                      <div className="bot-name">{name}</div>
                      <div className="bot-clan">{value}</div>
                    </div>
                  </div>
                </div>
              );
            } else
              return (
                <div
                  key={idx}
                  onClick={() => {
                    onIncompleteBotClick(tokenId);
                  }}
                  className={clx("bots-card", sidebarOpen ? "" : "card-sm")}
                >
                  <div className="bots-card-incomplete">
                    <img src={"./IncompleteBlockbot.png"} alt="" />
                  </div>
                  <div className="bots-details">
                    <div>
                      <div className="bot-name">
                        Continue Deflatinate #{tokenId}
                      </div>
                    </div>
                  </div>
                </div>
              );
          })}
        </div>
      );
    return (
      <div className="empty-state">
        <span>
          Aww snap! Seems like you don't own a BlockBot. <br /> Get your own
          BlockBot from OpenSea.
        </span>
        <div className="blue-container">
          <img src="/get-a-bb.png" alt="" />
        </div>
        <Button
          href="https://opensea.io/collection/blockbots-by-indorse"
          target="_blank"
          referrerPolicy="no-referrer"
          rel="noreferrer"
          className="blue-button"
        >
          Let's Go
        </Button>
      </div>
    );
  };

  const children = getChildren();
  return children;
};

export default withWeb3(Home);
