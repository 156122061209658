export const truncateString = (value, start = 5, end = 5) => {
  if (value && typeof value === "string") {
    return (
      value.substr(0, start) +
      "..." +
      value.substr(value.length - end, value.length)
    );
  }
  return "";
};

export const getIcons = (type) => {
  switch (type) {
    case "Clan":
      return <img src="/clan-img.png" alt="" />;
    case "Sub Clan":
      return <img src="/sub-clan-img.png" alt="" />;
    case "Headgear":
      return <img src="/head-gear-img.png" alt="" />;
    case "Head":
      return <img src="/head-img.png" alt="" />;
    case "Hands":
      return <img src="/hands-img.png" alt="" />;
    case "Body":
      return <img src="/body-img.png" alt="" />;
    case "Legs":
      return <img src="/legs-img.png" alt="" />;
    case "Primary Weapon":
      return <img src="/primary-weapon-img.png" alt="" />;
    case "Secondary Weapon":
      return <img src="/secondary-weapon-img.png" alt="" />;
    default:
      return null;
  }
};
