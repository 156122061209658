import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import { useSelector } from "react-redux";

import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Home from "./containers/Home";
import Showcase from "./containers/Showcase";
import Deflatinator from "./containers/Deflatinator";
import DeflatinatorPage from "./containers/Deflatinator/DeflatinatorPage";
import FAQ from "./containers/FAQ";
import OpenSeaSS from "./containers/OpenSeaSS";
import { BlockbotStats } from "./containers";
import { DesktopOnly } from "./components";
import { getErrorMessage, injected } from "./blockchain";
import { onReset } from "./app/reducers/blockbotReducer";
import "./styles/styles.scss";
// import { IS_PROD } from "./constant";
import { isMobile } from "react-device-detect";

const LayoutWithSidebar = (props) => {
  const { account, selectedBot, basePowers, children } = props;
  return (
    <div className="d-flex w-100">
      <Sidebar
        isConnected={account}
        basePowers={basePowers}
        selectedBot={selectedBot}
      />
      <div className="main-wrapper">{children}</div>
    </div>
  );
};

function App(props) {
  const { activate, account } = useWeb3React();

  const selectedBot = useSelector(({ blockbot }) => {
    return blockbot.selectedBot;
  });

  const basePowers = useSelector(({ blockbot }) => {
    return blockbot.basePower;
  });

  const dispatch = useDispatch();
  const connectWallet = async () => {
    try {
      await activate(injected);
    } catch (err) {
      console.log(err);
      getErrorMessage(err);
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (
      (location && location.pathname === "/") ||
      location.pathname === "/faq" ||
      location.pathname === "/deflatinator"
    )
      dispatch(onReset());
  }, [dispatch, location]);

  const showcaseRef = useRef();
  const { current } = showcaseRef;
  // const isProd = IS_PROD === "true";
  const noHeaderFooter = location.pathname.includes("/OpenSeaSS");
  // console.log(noHeaderFooter);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {!noHeaderFooter && (
        <Header
          account={account}
          isDisabled={account}
          onConnectClick={connectWallet}
          selectedBot={selectedBot}
          showcaseRefFuncs={current}
        />
      )}
      <div>
        <div className="main-container">
          {/* <Sidebar isConnected={account} selectedBot={selectedBot} />
          <div className="main-wrapper"> */}
          <Routes>
            <Route
              exact
              path="/"
              element={
                <LayoutWithSidebar account={account} selectedBot={selectedBot}>
                  <Home />
                </LayoutWithSidebar>
              }
            />
            <Route
              path="/showcase/:id"
              element={
                <LayoutWithSidebar
                  account={account}
                  selectedBot={selectedBot}
                  basePowers={basePowers}
                >
                  <Showcase ref={showcaseRef} />
                </LayoutWithSidebar>
              }
            />
            <Route path="/deflatinator" element={<Deflatinator />} />
            <Route
              path="/deflatinator/:botOneId/:botTwoId"
              element={!isMobile ? <DeflatinatorPage /> : <DesktopOnly />}
            />
            <Route
              path="/deflatinator/:botOneId/:botTwoId/:newBotId"
              element={
                !isMobile ? (
                  <DeflatinatorPage fromContract={true} />
                ) : (
                  <DesktopOnly />
                )
              }
            />
            <Route
              path="/faq/*"
              element={
                <LayoutWithSidebar account={account} selectedBot={selectedBot}>
                  <FAQ />
                </LayoutWithSidebar>
              }
            />
            <Route path="/OpenSeaSS/:id" element={<OpenSeaSS />} />
            <Route path="/bot-career" element={<BlockbotStats />} />
          </Routes>

          {/* </div> */}
          <ToastContainer />
        </div>
        {!noHeaderFooter && <div className="footer">Powered by Indorse</div>}
      </div>
    </div>
  );
}

export default App;
