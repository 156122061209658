/* eslint-disable react/jsx-no-target-blank */
import React, {
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";

import { useParams } from "react-router-dom";
import { API_URL } from "../../constant";
import useAxios from "../../hooks/useNormalAxios";

import useThree from "../../hooks/ThreeJsHooks/useThree";
// import { Loader } from "../../components";
// import { Button } from "reactstrap";

const OpenSeaSS = React.forwardRef((props, ref) => {
  const [assetMap, setAssetMap] = useState({});
  // const [loading, setLoading] = useState(true);
  const { response: botData, fetchData } = useAxios();
  const params = useParams();
  const { id } = params;

  const { loadLayer, loadBackdrop, mountRef, scene, renderer, camera } =
    useThree();

  useEffect(() => {
    const getBotData = async () => {
      await fetchData(`${API_URL}/blockbot/${id}`);
      // setLoading(false);
    };
    if (id) {
      getBotData();
    }
  }, [fetchData, id]);

  useLayoutEffect(() => {
    loadBackdrop();
    // console.log(botData);
    if (botData) {
      const { attributes, name } = botData;
      let obj = { name };
      attributes.map((elem) => {
        const { trait_type, value } = elem;
        if (trait_type === "Clan") {
          return null;
        }
        if (trait_type === "Clan" || trait_type === "Sub Clan") {
          obj[trait_type] = value;
          return null;
        }
        obj[trait_type] = elem["3DAssetLink"];
        return null;
      });
      setAssetMap(obj);
    }
  }, [botData, loadBackdrop, mountRef]);

  useLayoutEffect(() => {
    // console.log(assetMap);
    // console.log(mountRef);
    if (mountRef) {
      const { Head, Body, Headgear, Legs, Hands } = assetMap;
      const PrimaryWeapon = assetMap["Primary Weapon"];
      const SecondaryWeapon = assetMap["Secondary Weapon"];
      Head && loadLayer(Head, "head");
      Headgear && loadLayer(Headgear, "headGear");
      Body && loadLayer(Body, "body");
      Hands && loadLayer(Hands, "hands");
      Legs && loadLayer(Legs, "legs");
      PrimaryWeapon && loadLayer(PrimaryWeapon, "primaryWeapon");
      SecondaryWeapon && loadLayer(SecondaryWeapon, "secondaryWeapon");
    }
  }, [assetMap, loadLayer, mountRef]);

  const downloadPNG = useCallback(() => {
    const width = mountRef.current.clientWidth;
    const height = mountRef.current.clientHeight;

    renderer.setSize(width * 6, height * 6);
    renderer.render(scene, camera);
    renderer.domElement.toBlob(
      function (blob) {
        var a = document.createElement("a");
        var url = URL.createObjectURL(blob);
        a.href = url;
        a.download = `${id}.png`;
        a.click();
      },
      "image/png",
      1.0
    );
    renderer.setSize(width, height);
  }, [camera, id, mountRef, renderer, scene]);

  //   if (loading) return <Loader />;

  return (
    <div className="d-flex flex-columns justify-content-center align-items-center w-100">
      <div style={{ width: "600px" }}>
        <div ref={mountRef} className="three-container" />
      </div>
      <button onClick={downloadPNG} id="download-btn">
        Donwload
      </button>
    </div>
  );
});

export default OpenSeaSS;
