import { useEffect } from "react";

import withWeb3 from "../../hoc/withWeb3";
import { useState, useCallback } from "react";
import { Loader, BasicLoader } from "../../components";
import { useNavigate } from "react-router-dom";
// import useAxios from "../../hooks/useAxios";
import clx from "classnames";
import { Button, UncontrolledTooltip } from "reactstrap";
import { useParams } from "react-router";
import useThree from "../../hooks/ThreeJsHooks/useThree";
import axios from "axios";
import { API_URL, SUB_CLAN_PRIORITY } from "../../constant";
import { FaChevronLeft, FaInfoCircle, FaLayerGroup } from "react-icons/fa";
import { MdOutlineRefresh, MdCheckCircle } from "react-icons/md";
import {
  HiOutlineArrowNarrowRight,
  HiOutlineArrowNarrowLeft,
} from "react-icons/hi";
import LayerCard from "../../components/DnDComponents/LayerCard";
import LayerCardDrop from "../../components/DnDComponents/LayerCardDrop";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import { toast } from "react-toastify";
import { ImInfo } from "react-icons/im";
import { DeflatinatorModal } from "./DeflatinatorModal";
import { StatsModal } from "./StatsModal";

const botAssetArr = [
  "Headgear",
  "Head",
  "Body",
  "Hands",
  "Legs",
  "Primary Weapon",
  "Secondary Weapon",
];

const clanPriority = {
  Citizen: 0,
  Soldier: 1,
  Wizard: 2,
  Superhero: 3,
  Royalty: 4,
};

const DEFAULT_BOT_STATE = {
  Headgear: { name: "", assetLink: "", customId: "", clan: "" },
  Head: {
    name: "",
    assetLink: "",
    customId: "",
    required: true,
    clan: "",
  },
  Body: { name: "", assetLink: "", customId: "", required: true, clan: "" },
  Hands: { name: "", assetLink: "", customId: "", required: true, clan: "" },
  Legs: { name: "", assetLink: "", customId: "", required: true, clan: "" },
  "Primary Weapon": { name: "", assetLink: "", customId: "", clan: "" },
  "Secondary Weapon": { name: "", assetLink: "", customId: "", clan: "" },
};
const DeflatinatorPage = (props) => {
  const {
    getNextDeflatinatedTokenId,
    library,
    deflatinate,
    fromContract = false,
  } = props;
  const [loading, setLoading] = useState(true);
  const [staticBotData, setStaticBotData] = useState({
    botOne: {},
    botTwo: {},
  });
  const navigate = useNavigate();
  const [newBot, setNewBotData] = useState(DEFAULT_BOT_STATE);
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  const [rarity, setRarity] = useState({
    botOne: null,
    botTwo: null,
    newBot: null,
  });
  const [openStatsModal, setStatsModal] = useState(false);
  const threeConfig = {
    controls: {
      maxZoom: 100,
      enableZoom: "false",
    },
    camera: {
      staticZoom: 70,
    },
    loadDefault: true,
  };

  const [newBotClan, setNewBotClan] = useState({
    clan: null,
    subClan: null,
  });
  const {
    loadBackdrop,
    mountRef,
    updateLayer,
    removeLayer,
    clearScene,
    renderer,
  } = useThree(threeConfig);

  const params = useParams();
  const { botOneId, botTwoId, newBotId } = params;

  const fetchRarity = useCallback(async (attributes, botOrder) => {
    try {
      setRarity((prevState) => ({
        ...prevState,
        [botOrder]: null,
      }));
      const rarityResponse = await axios.post(
        `https://api.blockbots.gg/rarity`,
        {
          attributes,
        }
      );
      const { data } = rarityResponse;
      const { approximateRank } = data;
      setRarity((prevState) => ({
        ...prevState,
        [botOrder]: approximateRank,
      }));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const cookDataForRender = useCallback(
    (data, botOrder) => {
      if (!data) return null;
      const { name, image, tokenId, attributes } = data;
      let Ob = {};
      let clanOb = {};
      fetchRarity(attributes, botOrder);
      attributes.forEach((elm) => {
        if (elm["3DAssetLink"])
          Ob[elm.trait_type] = {
            name: elm.value,
            assetLink: elm["3DAssetLink"],
          };
        else clanOb[elm.trait_type] = elm.value;
      });
      return { ...Ob, name, image, tokenId, clanOb };
    },
    [fetchRarity]
  );

  const getAllBotData = useCallback(() => {
    setLoading(true);
    const botOneReq = axios
      .get(`${API_URL}/blockbot/${botOneId}`)
      .then((res) => {
        const { data: botOne } = res;
        return botOne;
      })
      .catch(() => null);
    const botTwoReq = axios
      .get(`${API_URL}/blockbot/${botTwoId}`)
      .then((res) => {
        const { data: botTwo } = res;
        return botTwo;
      })
      .catch(() => null);
    axios
      .all([botOneReq, botTwoReq])
      .then(
        axios.spread((botOne, botTwo) => {
          setStaticBotData((prevState) => ({
            ...prevState,
            botOne: cookDataForRender(botOne, "botOne"),
            botTwo: cookDataForRender(botTwo, "botTwo"),
          }));
        })
      )
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [botOneId, botTwoId, cookDataForRender]);

  useEffect(() => {
    if (botOneId && botTwoId) {
      getAllBotData();
    }
  }, [botOneId, botTwoId, getAllBotData]);

  useEffect(() => {
    if (mountRef.current && !loading) {
      loadBackdrop(true);
    }
  }, [loadBackdrop, loading, mountRef]);

  const onEquipLayer = (layerName, assetLink, name, customId, clan) => {
    setNewBotData((prevState) => ({
      ...prevState,
      [layerName]: { name, assetLink, customId, clan },
    }));
    updateLayer(layerName, assetLink);
  };

  const onRemoveLayer = (layerName) => {
    setNewBotData((prevState) => ({
      ...prevState,
      [layerName]: DEFAULT_BOT_STATE[layerName],
    }));
    removeLayer(layerName);
  };

  const onRefresh = () => {
    setNewBotData(DEFAULT_BOT_STATE);
    clearScene(botAssetArr);
    setRarity((prevState) => ({
      ...prevState,
      newBot: null,
    }));
  };

  const { botOne, botTwo } = staticBotData;
  const {
    image: botOneImg,
    tokenId: botOneTokenId,
    clanOb: botOneClanOb,
  } = botOne;
  const {
    image: botTwoImg,
    tokenId: botTwoTokenId,
    clanOb: botTwoClanOb,
  } = botTwo;
  // console.log(botOneClanOb);
  const botOneClan = botOneClanOb && botOneClanOb.Clan;
  const botTwoClan = botTwoClanOb && botTwoClanOb.Clan;

  const onFinalize = () => {
    setStatsModal(true);
  };

  useEffect(() => {
    if (loading) return;
    let attributes = [];
    botAssetArr.forEach((elem) => {
      const asset = newBot[elem];
      const { name, assetLink } = asset;
      if (name !== "")
        attributes.push({
          trait_type: elem,
          "3DAssetLink": assetLink,
          value: name,
        });
    });
    const newClan =
      clanPriority[botOne.clanOb.Clan] > clanPriority[botTwo.clanOb.Clan]
        ? botOne.clanOb
        : botTwo.clanOb;
    const newSubClan =
      SUB_CLAN_PRIORITY[botOne.clanOb["Sub Clan"]] >
      SUB_CLAN_PRIORITY[botTwo.clanOb["Sub Clan"]]
        ? botOne.clanOb
        : botTwo.clanOb;
    attributes.push(
      { trait_type: "Clan", value: newClan.Clan },
      { trait_type: "Sub Clan", value: newSubClan["Sub Clan"] }
    );
    setNewBotClan({
      clan: newClan.Clan,
      subClan: newSubClan["Sub Clan"],
    });
    attributes.length > 4 && fetchRarity(attributes, "newBot");
  }, [botOne.clanOb, botTwo.clanOb, fetchRarity, loading, newBot]);

  const onConfirm = () => {
    setStatsModal(false);
    setProgressModalOpen(true);
  };

  return (
    <div className="deflat-wrapper">
      <div className="page-top-controls">
        <Button
          onClick={() => {
            navigate("/deflatinator");
          }}
          className="control-btn-white lg-btn"
        >
          <FaChevronLeft /> Back to all BlockBots
        </Button>
        {!loading && (
          <div className="controls-btn-cont">
            <Button onClick={onRefresh} className="control-btn-white">
              <MdOutlineRefresh />
              Reset
            </Button>
            <Button onClick={onFinalize} className="control-btn-white blue-bg">
              <MdCheckCircle />
              Finalize
            </Button>
          </div>
        )}
      </div>
      <div className="def-three-wrapper">
        <div className={clx("static-bots-container")}>
          {!loading && (
            <div className="def-ranking">
              <img src="/rank.svg" alt="" />
              {rarity.botOne ? (
                <span className="def-ranking-val">{rarity.botOne}</span>
              ) : (
                <BasicLoader style={{ fontSize: "32px" }} />
              )}
              <span className="def-ranking-title">Ranking</span>
            </div>
          )}
          {!loading && <HiOutlineArrowNarrowRight className="bot-one-arrow" />}
          {botOneImg && (
            <img className="static-bot-img" src={botOneImg} alt="" />
          )}
          {!loading && (
            <div className={clx("def-clan-info", "static")}>
              {botOne.clanOb && (
                <div className="clan-info">{botOne.clanOb.Clan}</div>
              )}
              {botOne.clanOb && (
                <div className="subclan-info">{botOne.clanOb["Sub Clan"]}</div>
              )}
            </div>
          )}
        </div>
        <div ref={mountRef} className="def-three-scene">
          {!loading && (
            <div className={clx("def-ranking", "new-bot-ranking")}>
              <div className="d-flex">
                <img src="/rank.svg" alt="" />
                <FaInfoCircle id="rank-info" className="rank-info" />
                <UncontrolledTooltip
                  className="showcase-tooltip"
                  target="rank-info"
                  autohide={false}
                >
                  This ranking is an estimate and might change in the future
                  based on other factors.
                </UncontrolledTooltip>
              </div>
              {rarity.newBot ? (
                <span className="def-ranking-val">{rarity.newBot}</span>
              ) : (
                <BasicLoader style={{ fontSize: "32px" }} />
              )}
              <span className="def-ranking-title">Ranking</span>
            </div>
          )}
          {!loading && (
            <div className="def-clan-info">
              {newBotClan.clan && (
                <div className="clan-info">{newBotClan.clan}</div>
              )}
              {newBotClan.subClan && (
                <div className="subclan-info">{newBotClan.subClan}</div>
              )}
            </div>
          )}
        </div>
        <div className={clx("static-bots-container")}>
          {!loading && (
            <div className="def-ranking">
              <img src="/rank.svg" alt="" />
              {rarity.botTwo ? (
                <span className="def-ranking-val">{rarity.botTwo}</span>
              ) : (
                <BasicLoader style={{ fontSize: "32px" }} />
              )}
              <span className="def-ranking-title">Ranking</span>
            </div>
          )}
          {!loading && <HiOutlineArrowNarrowLeft className="bot-two-arrow" />}
          {botTwoImg && (
            <img className="static-bot-img" src={botTwoImg} alt="" />
          )}
          {!loading && (
            <div className={clx("def-clan-info", "static")}>
              {botTwo.clanOb && (
                <div className="clan-info">{botTwo.clanOb.Clan}</div>
              )}
              {botTwo.clanOb && (
                <div className="subclan-info">{botTwo.clanOb["Sub Clan"]}</div>
              )}
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <div className="empty-state mt-5">
          <Loader />
        </div>
      ) : (
        <DndProvider backend={HTML5Backend}>
          <div className="layers-wrapper">
            <div className="deflat-warning-cont">
              <div className="deflat-warning">
                <ImInfo />
                <b>Please Note :</b> No existing powerups will be lost. Please
                check the{" "}
                <a
                  referrerPolicy="no-referrer"
                  target="_blank"
                  href="/faq/deflatinator"
                >
                  FAQs
                </a>{" "}
                for more information.
              </div>
            </div>
            <div className="layers-heading">
              <FaLayerGroup /> Layers
            </div>
            <div className="layers-container">
              <div className="bot-one-layers">
                {Object.keys(botOne).length > 0 &&
                  botAssetArr.map((elem, idx) => {
                    const layer = botOne[elem];
                    const name = layer && layer.name;
                    const assetLink = layer && layer.assetLink;
                    const customId = `${botOneTokenId}_${name}`;
                    const equipped = customId === newBot[elem].customId;
                    return (
                      <LayerCard
                        key={idx}
                        title={elem}
                        clan={botOneClan}
                        name={name}
                        assetLink={assetLink}
                        onButtonClick={onEquipLayer}
                        customId={customId}
                        equipped={equipped}
                      />
                    );
                  })}
              </div>
              <div>
                {Object.keys(newBot).length > 0 &&
                  botAssetArr.map((elem, idx) => {
                    const layer = newBot[elem];
                    const name = layer && layer.name;
                    return (
                      <LayerCardDrop
                        key={idx}
                        title={elem}
                        name={name}
                        onButtonClick={onRemoveLayer}
                      />
                    );
                  })}
              </div>
              <div>
                {Object.keys(botTwo).length > 0 &&
                  botAssetArr.map((elem, idx) => {
                    const layer = botTwo[elem];
                    const name = layer && layer.name;
                    const assetLink = layer && layer.assetLink;
                    const customId = `${botTwoTokenId}_${name}`;
                    const equipped = customId === newBot[elem].customId;
                    return (
                      <LayerCard
                        key={idx}
                        title={elem}
                        name={name}
                        clan={botTwoClan}
                        assetLink={assetLink}
                        onButtonClick={onEquipLayer}
                        customId={customId}
                        equipped={equipped}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </DndProvider>
      )}
      {progressModalOpen && (
        <DeflatinatorModal
          botOne={botOne}
          botTwo={botTwo}
          isOpen={progressModalOpen}
          botAssetArr={botAssetArr}
          clanPriority={clanPriority}
          deflatinate={deflatinate}
          getNextDeflatinatedTokenId={getNextDeflatinatedTokenId}
          library={library}
          newBot={newBot}
          onClose={() => {
            setProgressModalOpen(false);
          }}
          fromContract={fromContract}
          newBotId={newBotId}
        />
      )}
      {openStatsModal && (
        <StatsModal
          newBot={newBot}
          onCancel={() => setStatsModal(false)}
          isOpen={openStatsModal}
          renderer={renderer}
          botOne={botOne}
          botTwo={botTwo}
          onConfirm={onConfirm}
          botClan={newBotClan.clan}
        />
      )}
    </div>
  );

  // const children = getChildren();
  // return children;
};

export default withWeb3(DeflatinatorPage);
