export const API_URL = process.env.REACT_APP_API_URL;
export const BLOCKBOT_CONTRACT_ADDRESS =
  process.env.REACT_APP_BLOCKBOT_CONTRACT;
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
export const CHANGE_NAME_URI = process.env.REACT_APP_CHANGE_NAME_URI;

const chainConfig = {
  1: "Ethereum Mainnet",
  4: "Rinkeby Testnet",
};

const chainHexConfig = {
  1: "0x1",
  4: "0x4",
};

export const SUB_CLAN_PRIORITY = {
  Male: 0,
  Female: 1,
  Ironbot: 3,
};

export const CHAIN_NAME = chainConfig[CHAIN_ID];

export const CHAIN_HEX = chainHexConfig[CHAIN_ID];

export const IS_PROD = process.env.REACT_APP_IS_PROD;
