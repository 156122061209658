import { Button } from "reactstrap";
import { getIcons } from "../../utils";
import { DropTarget } from "react-dnd";
import clx from "classnames";

const LayerCardDrop = (props) => {
  const { title, name, onButtonClick, canDrop, connectDropTarget } = props;
  return connectDropTarget(
    <div
      style={{ border: canDrop ? "1px solid limegreen" : "" }}
      className={clx("layer-item", canDrop ? "pulsate" : "")}
    >
      {name ? (
        <div className="layer-desc">
          <div className="title">{title}</div>
          <div className="desc">{name || "N/a"}</div>
          <Button
            onClick={() => {
              onButtonClick(title);
            }}
            disabled={!name}
            className="layer-button"
          >
            Remove
          </Button>
        </div>
      ) : (
        <div className="empty-layer-card">
          Drag and drop a <b>{title}</b> item from either side or press{" "}
          <b>Equip</b>.
        </div>
      )}
      <div className="layer-logo">{getIcons(title)}</div>
    </div>
  );
};

export default DropTarget(
  ({ title: eligibleLayer }) => {
    return eligibleLayer;
  },
  {},
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  })
)(LayerCardDrop);
