import { configureStore } from "@reduxjs/toolkit";
import blockbotReducer from "./reducers/blockbotReducer";
import sidebarReducer from "./reducers/sidebarReducer";

export const store = configureStore({
  reducer: {
    blockbot: blockbotReducer,
    sidebar: sidebarReducer,
  },
});
