import { useEffect, useMemo } from "react";

import makeFox from "@metamask/logo";

const MetamaskFox = (props) => {
  const { containerRef: container } = props;
  const viewer = useMemo(
    () =>
      makeFox({
        pxNotRatio: true,
        width: 300,
        height: 300,
        // To make the face follow the mouse.
        followMouse: true,
        // head should slowly drift (overrides lookAt)
        slowDrift: false,
      }),
    []
  );

  useEffect(() => {
    if (!container) return;
    viewer.lookAt({ x: 100, y: 100 });
    container.current.appendChild(viewer.container);

    return () => {
      viewer.stopAnimation();
      const { current } = container;
      current && current.removeChild(viewer.container);
    };
  }, [container, viewer]);

  return null;
};

export default MetamaskFox;
