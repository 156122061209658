import { useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import { truncateString, getIcons } from "../../utils";
import { useNavigate } from "react-router-dom";

const HamburgerMenu = (props) => {
  const { account, onConnectClick, downloadModel, downloadPNG, selectedBot } =
    props;
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const [showLayers, toggleLayers] = useState(false);
  const navigate = useNavigate();

  const onConnect = () => {
    onConnectClick();
    setCollapsed(true);
  };

  const onDownloadModel = () => {
    downloadModel();
    setCollapsed(true);
  };

  const onDownloadPFP = () => {
    downloadPNG();
    setCollapsed(true);
  };

  return (
    <div className={""}>
      <Navbar
        expand="lg"
        className="d-flex justify-content-between"
        color="faded"
        light
      >
        <div className="d-flex align-items-center">
          <NavbarBrand href="/" className="header-bb-logo mr-auto" />
          <div className="header-title">BlockBots</div>
        </div>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse
          className="mobile-nav-collapse"
          isOpen={!collapsed}
          navbar={true}
        >
          <div onClick={onConnect} className="mobile-nav-item">
            {account ? truncateString(account, 5, 11) : "Connect"}
          </div>
          <div
            onClick={() => {
              navigate("/");
            }}
            className="mobile-nav-item"
          >
            Home
          </div>
          <div
            onClick={() => {
              navigate("/bot-career");
            }}
            className="mobile-nav-item"
          >
            BlockBot Career
          </div>
          <div
            onClick={() => {
              navigate("/faq");
            }}
            className="mobile-nav-item"
          >
            FAQ
          </div>
          {selectedBot.length > 0 && (
            <>
              <div
                onClick={() => {
                  toggleLayers(true);
                }}
                className="mobile-nav-item"
              >
                Layers
              </div>
              <div onClick={onDownloadModel} className="mobile-nav-item">
                Dowload 3D Model
              </div>
              <div onClick={onDownloadPFP} className="mobile-nav-item">
                Dowload PFP
              </div>
            </>
          )}

          {/* <div onClick={onDownloadPFP} className="mobile-nav-item">
            Into the Metaverse
          </div> */}
        </Collapse>
      </Navbar>
      <Offcanvas isOpen={showLayers}>
        <OffcanvasHeader
          toggle={() => {
            toggleLayers(false);
          }}
        >
          Layers
        </OffcanvasHeader>
        <OffcanvasBody>
          {selectedBot.map((elem, idx) => {
            const { trait_type, value } = elem;
            return (
              <div key={idx} className="sidebar-layer-collapse-item">
                <div className="sidebar-layer-logo">{getIcons(trait_type)}</div>
                <div className="sidebar-layer-desc">
                  <div className="title">{trait_type}</div>
                  <div className="desc">{value}</div>
                </div>
              </div>
            );
          })}
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default HamburgerMenu;
