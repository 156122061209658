import { useEffect, useState, useCallback, useRef, forwardRef } from "react";
import { useWeb3React } from "@web3-react/core";
import useEagerConnect from "../hooks/useEagerConnect";
import { useInactiveListener } from "../hooks/useInactiveListener";
import { useBlockBotsContract } from "../hooks/useBlockBotsContract";
import { injected } from "../blockchain/connectors";
import { getErrorMessage } from "../blockchain";
import { useNavigate } from "react-router-dom";
import MetamaskFox from "../components/MetamaskFox";
import { Button } from "reactstrap";
import { useINDContaract } from "../hooks/useINDContract";
import { useAutoSwitchNetwork } from "../hooks/useAutoSwitchNetwork";
import { CHAIN_NAME } from "../constant";

const WithWeb3 = (Component) => {
  const ReallySmartComponent = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const triedEager = useEagerConnect();
    useAutoSwitchNetwork();
    const { activate, account, connector, library } = useWeb3React();

    const connectWallet = useCallback(async () => {
      try {
        await activate(injected, undefined, true);
      } catch (err) {
        console.log(err);
        getErrorMessage(err);
      }
    }, [activate]);
    // useEffect(() => {
    //   connectWallet();
    // }, [account, chainId, active, connectWallet]);

    const [activatingConnector, setActivatingConnector] = useState();

    useEffect(() => {
      if (activatingConnector && activatingConnector === connector) {
        setActivatingConnector(undefined);
      }
    }, [activatingConnector, connector]);

    const onWeb3Update = () => {
      navigate("/");
    };
    const metamaskFoxRef = useRef(null);
    useInactiveListener(!triedEager || !!activatingConnector, onWeb3Update);
    // console.log("aacc", account);
    const {
      loadingBots,
      getTokenURI,
      changeNamePrice,
      changeBBName,
      indContractAddress,
      checkOwnership,
      changeNameInProgress,
      getNextDeflatinatedTokenId,
      deflatinate,
      getTokenIdsFromWallet,
      getParentIds,
    } = useBlockBotsContract();

    const { getApproval, waitingForApproval } = useINDContaract(
      changeNamePrice,
      indContractAddress
    );

    if (triedEager && !account) {
      return (
        <div className="empty-state">
          <span>
            Please connect your Metamask Wallet <br />
            <span className="sub-text">
              Also make sure that your wallet is connected to{" "}
              <b>{CHAIN_NAME}</b>{" "}
            </span>
          </span>
          <div ref={metamaskFoxRef} className="blue-container">
            {metamaskFoxRef && <MetamaskFox containerRef={metamaskFoxRef} />}
          </div>
          <Button onClick={connectWallet} className="blue-button">
            Connect
          </Button>
        </div>
      );
    }
    return (
      <Component
        {...props}
        fetchTokenUrlsFromWallet={getTokenURI}
        account={account}
        connectWallet={connectWallet}
        loading={loadingBots || !triedEager}
        ref={ref}
        getINDApproval={getApproval}
        changeBBName={changeBBName}
        checkOwnership={checkOwnership}
        waitingForApproval={waitingForApproval}
        changeNameInProgress={changeNameInProgress}
        getNextDeflatinatedTokenId={getNextDeflatinatedTokenId}
        library={library}
        deflatinate={deflatinate}
        getTokenIdsFromWallet={getTokenIdsFromWallet}
        getParentIds={getParentIds}
      />
    );
  });
  return ReallySmartComponent;
};

export default WithWeb3;
