import { useEffect } from "react";

import withWeb3 from "../../hoc/withWeb3";
import { useState } from "react";
import { Loader } from "../../components";
import { useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import clx from "classnames";
import { Button } from "reactstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { CustomImg } from "../../components";

const Deflatinator = (props) => {
  const navigate = useNavigate();
  const { account, loading, fetchTokenUrlsFromWallet } = props;
  const [selectedBots, setSelectedBots] = useState([]);
  // const [selectedClan, setSelectedClan] = useState(null);

  const onBotSelect = (tokenId, clan) => {
    const tempArr = [...selectedBots];
    if (tempArr.indexOf(tokenId) > -1) return;
    tempArr.push(tokenId);
    // if (tempArr.length === 1) setSelectedClan(clan);
    setSelectedBots(tempArr);
  };

  const onRemove = (index) => {
    const tempArr = [...selectedBots];
    tempArr.splice(index, 1);
    // if (tempArr.length === 0) setSelectedClan(null);
    setSelectedBots(tempArr);
  };

  const { dataLoading, response: botsDataArr, fetchData } = useAxios();

  useEffect(() => {
    const fetchAllData = async () => {
      let data = await fetchTokenUrlsFromWallet(true);
      if (data) fetchData(data);
    };
    if (account) {
      fetchAllData();
    }
  }, [account, fetchData, fetchTokenUrlsFromWallet]);

  const getChildren = () => {
    if (loading || dataLoading)
      return (
        <div className="empty-state">
          <Loader />
        </div>
      );
    if (Array.isArray(botsDataArr) && botsDataArr.length > 0)
      return (
        <div className="deflat-wrapper">
          <div className="deflat-banner-container">
            <div className="col-6">
              <div className="deflat-banner-cta">
                <div className="deflat-banner-text">
                  Choose any two bots from your inventory.
                  <br /> Deflatinate them to create a brand new bot.
                </div>
                <Button
                  onClick={() => {
                    navigate("/faq/deflatinator");
                  }}
                  className="banner-btn"
                >
                  Learn More
                </Button>
              </div>
            </div>
            <div className="col-6">
              <img className="deflat-banner-img" src="/podium.png" alt="" />
            </div>
          </div>
          <div className="padded-container">
            <div className="deflat-button-cont">
              <Button
                disabled={selectedBots.length !== 2}
                className="deflat-go-button"
                onClick={() => {
                  navigate(
                    `/deflatinator/${selectedBots[0]}/${selectedBots[1]}`
                  );
                }}
              >
                Go to Deflatinator
              </Button>
            </div>
            <div id="bots" className={clx("bots-container")}>
              {botsDataArr.map((elem, idx) => {
                const { image, name, attributes, tokenId } = elem;
                const clanAttr = attributes.filter(
                  (elm) => elm.trait_type === "Clan"
                );
                const { value } = clanAttr[0];
                const selectedIndex = selectedBots.indexOf(tokenId);
                const isSelected = selectedIndex > -1;
                return (
                  <div
                    key={idx}
                    style={{ cursor: "auto" }}
                    className={clx("bots-card", "card-sm")}
                  >
                    <div className="bots-card-img-cont">
                      <CustomImg src={image} />
                    </div>
                    <div className="bots-details">
                      <div>
                        <div className="bot-name">{name}</div>
                        <div className="bot-clan">{value}</div>
                      </div>
                      <div className="bot-cta">
                        <Button
                          disabled={selectedBots.length === 2 || isSelected}
                          onClick={() => {
                            onBotSelect(tokenId, value);
                          }}
                          className={clx(
                            isSelected
                              ? "selected-card-cta-button"
                              : "card-cta-botton"
                          )}
                        >
                          {isSelected ? "Selected" : "Select"}
                        </Button>
                        {isSelected && (
                          <TiDeleteOutline
                            onClick={() => {
                              onRemove(selectedIndex);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    return (
      <div className="empty-state">
        <span>
          Aww snap! Seems like you don't own a BlockBot. <br /> Get your own
          BlockBot from OpenSea.
        </span>
        <div className="blue-container">
          <img src="/get-a-bb.png" alt="" />
        </div>
        <Button
          href="https://opensea.io/collection/blockbots-by-indorse"
          target="_blank"
          referrerPolicy="no-referrer"
          rel="noreferrer"
          className="blue-button"
        >
          Let's Go
        </Button>
      </div>
    );
  };

  const children = getChildren();
  return children;
};

export default withWeb3(Deflatinator);
