import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useState, useEffect, useCallback } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL, SUB_CLAN_PRIORITY } from "../../constant";
import objectHash from "object-hash";
import { useNavigate } from "react-router";

export const DeflatinatorModal = ({
  isOpen,
  botAssetArr,
  clanPriority,
  botOne,
  botTwo,
  getNextDeflatinatedTokenId,
  newBot,
  deflatinate,
  library,
  onClose,
  newBotId,
  fromContract,
}) => {
  const [waitingForSignature, setWaitingForSignature] = useState(true);
  const [deflatinationInProgress, setDeflatinationInProgress] = useState(true);
  const navigate = useNavigate();

  const finalizeDeflatination = useCallback(async () => {
    setWaitingForSignature(true);
    const newTokenId = !fromContract
      ? await getNextDeflatinatedTokenId()
      : newBotId;
    let notComplete = false;
    const attributes = [];
    botAssetArr.forEach((elem) => {
      const asset = newBot[elem];
      const { name, required, clan } = asset;
      if (required && name === "") notComplete = true;
      if (name !== "") attributes.push({ trait_type: elem, value: name, clan });
    });
    if (notComplete) {
      toast(
        <span>
          Required layers are missing for deflatination.
          <br /> Please check that you have atleast equipped Head, Body, Hands
          and Legs layers
        </span>,
        {
          toastId: "NOT_COMPLETE",
        }
      );
      onClose();
      return;
    }
    const newClan =
      clanPriority[botOne.clanOb.Clan] > clanPriority[botTwo.clanOb.Clan]
        ? botOne.clanOb
        : botTwo.clanOb;
    const newSubClan =
      SUB_CLAN_PRIORITY[botOne.clanOb["Sub Clan"]] >
      SUB_CLAN_PRIORITY[botTwo.clanOb["Sub Clan"]]
        ? botOne.clanOb
        : botTwo.clanOb;
    attributes.push(
      { trait_type: "Clan", value: newClan.Clan, clan: newClan.Clan },
      {
        trait_type: "Sub Clan",
        value: newSubClan["Sub Clan"],
        clan: newSubClan.Clan,
      }
    );
    try {
      const hash = objectHash(attributes);
      const signer = library.getSigner();
      const signature = await signer.signMessage(hash);
      setWaitingForSignature(false);
      setDeflatinationInProgress(true);
      await axios.post(`${API_URL}/deflatinate`, {
        firstTokenId: botOne.tokenId,
        secondTokenId: botTwo.tokenId,
        attributes,
        newTokenId,
        draft: true,
        hash,
        signature,
      });

      !fromContract && (await deflatinate(botOne.tokenId, botTwo.tokenId));
      const finalizeHash = objectHash({
        firstTokenId: botOne.tokenId,
        secondTokenId: botTwo.tokenId,
        newTokenId,
      });
      const finalizeSign = await signer.signMessage(finalizeHash);
      await axios.post(`${API_URL}/deflatinate/finalize-draft`, {
        firstTokenId: botOne.tokenId,
        secondTokenId: botTwo.tokenId,
        newTokenId,
        hash: finalizeHash,
        signature: finalizeSign,
      });
      setDeflatinationInProgress(false);
      navigate(`/showcase/${newTokenId}`);
    } catch (err) {
      console.log(err);
    } finally {
      onClose();
    }
  }, [
    botAssetArr,
    botOne.clanOb,
    botOne.tokenId,
    botTwo.clanOb,
    botTwo.tokenId,
    clanPriority,
    deflatinate,
    fromContract,
    getNextDeflatinatedTokenId,
    library,
    navigate,
    newBot,
    newBotId,
    onClose,
  ]);

  useEffect(() => {
    if (isOpen) finalizeDeflatination();
  }, [finalizeDeflatination, isOpen]);

  return (
    <Modal className="progress-modal" size="lg" isOpen={isOpen}>
      <ModalHeader>Deflatinate</ModalHeader>
      <ModalBody>
        <div className="progress-modal-message">
          {waitingForSignature && "Please Sign the hash"}
          {deflatinationInProgress &&
            "Please Finalize and Confirm the transaction in Metamask"}
        </div>
        <div className="progress-modal-steps-container">
          <div className="progress-modal-step">
            {waitingForSignature ? (
              <div className="step-loader" />
            ) : (
              <FaCheckCircle />
            )}
            <div className="step-title">Sign Hash</div>
          </div>
          <div className="progress-modal-step">
            {deflatinationInProgress ? (
              <div className="step-loader" />
            ) : (
              <FaCheckCircle />
            )}
            <div className="step-title">Confirm Deflatination</div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
