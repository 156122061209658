import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
export const SidebarSkeletonLoading = () => {
  return (
    <div>
      <div>
        <Skeleton height={30} width={200} />
      </div>
      <hr />
      <div className="skeleton-loading-container">
        <div className="skeleton-circle">
          <Skeleton circle={true} height={70} />
        </div>
        <div className="skeleton-rest">
          <Skeleton height={70} />
        </div>
      </div>
      <div className="skeleton-loading-container">
        <div className="skeleton-circle">
          <Skeleton circle={true} height={70} />
        </div>
        <div className="skeleton-rest">
          <Skeleton height={70} />
        </div>
      </div>
      <div className="skeleton-loading-container">
        <div className="skeleton-circle">
          <Skeleton circle={true} height={70} />
        </div>
        <div className="skeleton-rest">
          <Skeleton height={70} />
        </div>
      </div>
      <hr />
      <Skeleton height={30} count={3} />
    </div>
  );
};
