/* eslint-disable react/jsx-no-target-blank */
import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  useCallback,
} from "react";
import { FaDiceD6, FaCamera, FaInfoCircle, FaBolt } from "react-icons/fa";
import { Button, Input, UncontrolledTooltip } from "reactstrap";
import JSZip from "jszip";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from "react-color";
import { toast } from "react-toastify";

import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { useParams } from "react-router-dom";
import { API_URL, CHANGE_NAME_URI } from "../../constant";
import { onBotSelect, setBasePowers } from "../../app/reducers/blockbotReducer";
import useAxios from "../../hooks/useAxios";
import { Loader } from "../../components";
import { OBJExporter } from "../../utils/OBJExporter";
import defaultImg from "./default.png";
import useThree from "../../hooks/ThreeJsHooks/useThree";
import withWeb3 from "../../hoc/withWeb3";
import { ChangeNameProgressModal } from "./ChangeNameModal";
import { CHAIN_ID } from "../../constant";

const layerNameMap = {
  Head: "head",
  "Head Gear": "headGear",
  Hands: "hands",
  Body: "body",
  Legs: "legs",
  "Primary Weapon": "primaryWeapon",
  "Secondary Weapon": "secondaryWeapon",
  Scene: "Scene",
};

const Showcase = React.forwardRef((props, ref) => {
  const {
    account,
    getINDApproval,
    changeBBName,
    checkOwnership,
    loading,
    waitingForApproval,
    changeNameInProgress,
  } = props;

  const [showColorPallet, setColorPalletOpen] = useState(false);
  const [assetMap, setAssetMap] = useState({});
  const [botClan, setBotClan] = useState(null);
  const [bbName, setBBName] = useState("");
  const [isOwner, setOwnership] = useState(true);
  const [powerupLoading, setPowerupLoading] = useState(true);
  const dispatch = useDispatch();
  const [trxInProgress, setTrxProgress] = useState(false);
  const { response: botData, fetchData } = useAxios();

  const { response: powerupData, fetchData: fetchPowerUp } = useAxios();
  const params = useParams();
  const { id } = params;
  const assetRef = useRef();

  const selectedBot = useSelector(({ blockbot }) => {
    return blockbot.selectedBot;
  });

  const sidebarOpen = useSelector(({ sidebar }) => {
    return sidebar.isOpen;
  });

  const {
    loadLayer,
    loadBackdrop,
    mountRef,
    setColor,
    selectedColor,
    handleResize,
    updateLayer,
    scene,
    renderer,
    camera,
  } = useThree();

  useEffect(() => {
    handleResize(sidebarOpen);
  }, [handleResize, sidebarOpen]);

  useEffect(() => {
    const getBotData = async () => {
      const isOwner = await checkOwnership(id);
      if (isOwner) {
        await fetchData(`${API_URL}/blockbot/${id}`);
      }
      setOwnership(isOwner);
    };
    if (id && account) {
      getBotData();
    }
  }, [account, checkOwnership, fetchData, id]);

  useEffect(() => {
    if (botData) {
      const { attributes, name, basePower } = botData;
      dispatch(onBotSelect(attributes));
      dispatch(setBasePowers(basePower));
      let obj = { name };
      attributes.map((elem) => {
        const { trait_type, value } = elem;
        if (trait_type === "Clan") {
          setBotClan(value);
          return null;
        }
        if (trait_type === "Clan" || trait_type === "Sub Clan") {
          obj[trait_type] = value;
          return null;
        }
        obj[trait_type] = elem["3DAssetLink"];
        return null;
      });
      setAssetMap(obj);
      setBBName(name);
      assetRef.current = obj;
    }
  }, [botData, dispatch]);

  const fetchPowerUpData = useCallback(async () => {
    setPowerupLoading(true);
    await fetchPowerUp(`${API_URL}/powerups/${account}`, { clan: botClan });
    setPowerupLoading(false);
  }, [account, botClan, fetchPowerUp]);

  useEffect(() => {
    if (botClan) fetchPowerUpData();
  }, [botClan, fetchPowerUpData]);

  useEffect(() => {
    if (mountRef.current && !loading) loadBackdrop();
  }, [loadBackdrop, loading, mountRef]);

  useEffect(() => {
    if (mountRef.current && !loading) {
      const { Head, Body, Headgear, Legs, Hands } = assetMap;
      const PrimaryWeapon = assetMap["Primary Weapon"];
      const SecondaryWeapon = assetMap["Secondary Weapon"];
      Head && loadLayer(Head, "head");
      Headgear && loadLayer(Headgear, "headGear");
      Body && loadLayer(Body, "body");
      Hands && loadLayer(Hands, "hands");
      Legs && loadLayer(Legs, "legs");
      PrimaryWeapon && loadLayer(PrimaryWeapon, "primaryWeapon");
      SecondaryWeapon && loadLayer(SecondaryWeapon, "secondaryWeapon");
    }
  }, [assetMap, loadLayer, loading, mountRef]);

  const onColorChange = (color) => {
    setColor(color.hex);
  };

  const downloadPNG = useCallback(() => {
    const width = mountRef.current.clientWidth;
    const height = mountRef.current.clientHeight;
    const { current: currentAsset } = assetRef;
    const { name } = currentAsset;
    renderer.setSize(width * 6, height * 6);
    renderer.render(scene, camera);
    renderer.domElement.toBlob(
      function (blob) {
        var a = document.createElement("a");
        var url = URL.createObjectURL(blob);
        a.href = url;
        a.download = `${name}.png`;
        a.click();
      },
      "image/png",
      1.0
    );
    renderer.setSize(width, height);
  }, [camera, mountRef, renderer, scene]);

  const downloadModel = useCallback(async () => {
    const gltfExporter = new GLTFExporter();
    const { name } = assetRef.current;
    const bd = scene.getObjectByName("Scene");
    //remove Peddestel
    scene.remove(bd);
    const zip = new JSZip();
    gltfExporter.parse(
      scene,
      async (result) => {
        //Add glb to zip
        zip.file(`${name}.glb`, result);
        const objExporter = new OBJExporter();
        const safeName = "bb_MTL_Lib";
        const objResult = objExporter.parse(scene, safeName);
        //Add Pedestel Back
        scene.add(bd);
        //Add obj and mtl to zip
        zip.file(`${name}.obj`, objResult.obj);
        zip.file(`${safeName}.mtl`, objResult.mtl);
        //get Base64 String
        const base64Str = defaultImg.split(",")[1];
        zip.file("default.png", base64Str, { base64: true });
        //Generate and Download Zip
        const zipFile = await zip.generateAsync({ type: "blob" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(zipFile);
        link.download = `${name}.zip`;
        link.click();
      },
      {
        binary: true,
      }
    );
  }, [scene]);

  const onEquipWeapon = (elm, type) => {
    let newArr = [...selectedBot];
    const assetLink = elm["3DAssetLink"];
    const layerName = layerNameMap[type];
    updateLayer(layerName, assetLink);
    const idx = newArr.findIndex((el) => el.trait_type === type);
    if (idx > -1)
      newArr[idx] = {
        value: elm.name,
        power: null,
        "3DAssetLink": assetLink,
        trait_type: type,
      };
    else
      newArr.push({
        value: elm.name,
        power: null,
        "3DAssetLink": assetLink,
        trait_type: type,
      });

    dispatch(onBotSelect(newArr));
  };

  useImperativeHandle(
    ref,
    () => ({
      downloadPNG,
      downloadModel,
    }),
    [downloadPNG, downloadModel]
  );

  const onChangeNameHandle = async () => {
    setTrxProgress(true);
    try {
      await getINDApproval();
      await changeBBName(id, bbName);
      const res = await axios.post(`${CHANGE_NAME_URI}/token/changename`, {
        tokenId: id,
      });
      if (res.status === 200) {
        toast("Your bot has been renamed", {
          toastId: "CHANGE_NAME",
        });
      }
    } catch (err) {
      console.log(err);
      if (err.code === 4001) {
        toast(
          "Rejected Request. Please accept the transaction in your Metamask Wallet.",
          { toastId: "REJECT_REQUEST" }
        );
      }
      if (err.message.indexOf("New name is same as the current one") > -1) {
        toast("New name is same as the current one", {
          toastId: "SAME_NAME_ERR",
        });
      }
    } finally {
      setTrxProgress(false);
    }
  };

  const MARKETPLACE_URI =
    parseInt(CHAIN_ID) === 1
      ? "https://marketplace.blockbots.gg"
      : "https://staging-powerups.herokuapp.com/";

  if (loading)
    return (
      <div className="d-flex justify-content-center">
        <Loader />
      </div>
    );
  if (!isOwner)
    return <div className="text-center">You Are Not Authorized</div>;
  return (
    <div className="showcase-wrapper">
      <div className="showcase-container">
        <div className="showcase-three-wrapper showcase-mobile">
          <div className="showcase-edit-name">
            <div className="label">
              BlockBot Name
              <FaInfoCircle id="change-name-info" />
              <UncontrolledTooltip
                className="showcase-tooltip"
                target="change-name-info"
                autohide={false}
              >
                <ul>
                  <li>
                    Make sure you have 100 IND in your wallet. You can get IND
                    from{" "}
                    <a
                      href="https://v2.info.uniswap.org/pair/0xd5b1523b48d41c46e5d1091a9b068159353ff3d7"
                      referrerPolicy="no-referrer"
                      target="blank"
                    >
                      Uniswap
                    </a>
                    .
                  </li>
                  <li>
                    Once you click on "Change Name", you'll be asked to approve
                    two Metamask transactions -<br />
                    1st to approve the spend limit for IND.
                    <br />
                    2nd is to finalize transaction.
                  </li>
                  <li>
                    Approve the transactions and voila, the name should be
                    changed.
                  </li>
                </ul>
              </UncontrolledTooltip>
            </div>
            <div className="d-flex justify-content-between showcase-edit-mobile">
              <Input
                value={bbName}
                className="change-name-input"
                onChange={(e) => {
                  setBBName(e.target.value);
                }}
              ></Input>
              <Button
                onClick={onChangeNameHandle}
                className="change-name-button"
                disabled={trxInProgress}
              >
                {trxInProgress ? "Please Wait" : "Change Name"}
              </Button>
            </div>
          </div>
          <div className="three-wrapper">
            <div className="three-actions-container">
              <FaDiceD6 id="download3D" onClick={downloadModel} />
              <UncontrolledTooltip target="download3D">
                Download 3D Model of your BlockBot.
              </UncontrolledTooltip>
              <div
                onClick={() => {
                  setColorPalletOpen((prevState) => !prevState);
                }}
                id="changeColor"
                style={{ backgroundColor: selectedColor }}
                className="color-selector"
              >
                {showColorPallet && (
                  <div className="color-pallet-popover">
                    <SketchPicker
                      color={selectedColor}
                      onChange={onColorChange}
                    />
                  </div>
                )}
                <UncontrolledTooltip target="changeColor">
                  Change the background by choosing the color from the palette
                  box.
                </UncontrolledTooltip>
              </div>
              <FaCamera id="downloadPFP" onClick={downloadPNG} />
              <UncontrolledTooltip target="downloadPFP">
                Download PNG image of your BlockBot.
              </UncontrolledTooltip>
            </div>
            <div ref={mountRef} className="three-container" />
          </div>
        </div>
      </div>
      <div className="powerup-container">
        <div className="powerup-title">
          <FaBolt /> Powerups
        </div>
        {powerupData && powerupData.powerups.length
          ? powerupData.powerups.map((elm, idx) => {
              const { name, image, type } = elm;
              return (
                <div key={idx} className="power-card">
                  <div className="power-desc">
                    <div className="title">Weapon</div>
                    <div className="name">{name}</div>
                    <Button
                      onClick={() => {
                        onEquipWeapon(elm, type);
                      }}
                      className="blue-button"
                    >
                      Equip
                    </Button>
                  </div>
                  <div className="power-img">
                    <img src={image} alt="" />
                  </div>
                </div>
              );
            })
          : !powerupLoading && (
              <div className="powerup-empty-state">
                <div className="img-container">
                  <img src="/powerup-empty.png" alt="" />
                </div>
                <div className="description">
                  Visit Powerups Marketplace to mint your powerups
                </div>
                <Button
                  href={MARKETPLACE_URI}
                  target="_blank"
                  className="white-btn"
                >
                  Go to Marketplace
                </Button>
              </div>
            )}
      </div>
      {trxInProgress && (
        <ChangeNameProgressModal
          isOpen={trxInProgress}
          waitingForApproval={waitingForApproval}
          changeNameInProgress={changeNameInProgress}
        />
      )}
    </div>
  );
});

export default withWeb3(Showcase);
